import React, { useState } from "react";
import close from "../../Assets/Images/close.png";
import { useNavigate } from "react-router-dom";
import { LiaAngleLeftSolid } from "react-icons/lia";
import bankImage from "../../Assets/Images/bank.png";

import { useDispatch, useSelector } from "react-redux";
import { getUserBanks } from "../../store/actions/bank/getUserBanks";
import { deleteUserBank } from "../../store/actions/bank/deleteUserBank";
import Loader from "../Utils/Loader";
import { saveWithdrawalAccount } from "../../store/reducers/walletSlice";

const ChooseBank = ({ currency, next, handleClose, handleBack }) => {
  const navigate = useNavigate();
  const [selectedBank, setSelectedBank] = useState(null);
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state?.bank);
  const allBanksList = useSelector((state) => state?.bank?.banks) || [];
  const { userBanks } = useSelector((state) => state?.bank) || [];
  const [deleteLoading, setDeleteLoading] = useState(false);

  // const dispatch = useDispatch();

  const handleRemoveBank = (bankId, currency) => {
    setDeleteLoading(bankId);
    dispatch(
      deleteUserBank({
        bankId,
        setDeleteLoading,
        currency,
        cb: () => {
          dispatch(getUserBanks());
        },
      })
    );
  };

  const handleCardClick = (bankId) => {
    setSelectedBank(bankId);
  };

  const NGN_banks = userBanks?.filter(
    (bank) => bank.currency?.toLowerCase() === "ngr"
  );

  const USD_banks = userBanks?.filter(
    (bank) => bank?.currency?.toLowerCase() === "usd" && bank?.bank_type
  );

  return (
    <>
      <div className="flex flex-col justify-start items-start border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px] drop-shadow-custom relative bg-white">
        <div className="absolute p-3 top-3 right-3 cursor-pointer">
          <img
            src={close}
            alt="Close"
            className="cursor-pointer w-6 h-6"
            onClick={handleClose}
          />
        </div>
        <div className="flex pt-[20px] pb-[10px] flex-col justify-start items-center w-full h-full">
          <div className="flex  items-center justify-between w-full px-8 mt-[27px]">
            <div
              className="flex items-center gap-[px] cursor-pointer"
              onClick={handleBack}
            >
              <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-1" />
              <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
            </div>
            <div className="text-[16px] text-[#9c9c9e] font-[400]">
              Choose Bank Account
            </div>
            <div></div>
          </div>
          <div className="flex  flex-1 overflow-y-auto flex-col pt-[40px] gap-[20px]">
            {pending ? (
              <div className="w-full h-full flex">
                <Loader />
              </div>
            ) : (
              <div className="space-y-4  mt-[14px] ">
                {(currency === "Naira" ? NGN_banks : USD_banks)?.map((bank) => (
                  <div
                    onClick={() => {
                      dispatch(saveWithdrawalAccount(bank));
                      next();
                    }}
                    className="flex cursor-pointer drop-shadow-custom p-4 rounded-[14px] w-[90] mx-auto sm:mx-0 sm:w-[489px] h-[79px] items-center justify-between border-[1px] hover:border-[#6935d9]  border-[#F2F2F7] pb-4"
                    key={bank.id}
                  >
                    <div className="items-center flex">
                      <img
                        src={bankImage}
                        alt="Description 1"
                        className="w-[38px] h-[38px]"
                      />
                      <div className="flex flex-col flex-grow mx-4">
                        <h3 className="text-[16px] font-[500] text-[#131e3d]">
                          {bank?.account_name}
                        </h3>
                        <p className="text-[14px] font-[400] text-[#9C9C9E]">
                          {bank?.bank_name || bank?.bank_type} -{" "}
                          {bank?.account_number}
                        </p>
                      </div>
                    </div>
                    {/* <img
                        src={more}
                        alt="Description 1"
                        className="w-[28px] h-[28px] items-center "
                      /> */}
                  </div>
                ))}
              </div>
            )}
            <div className="text-[12px] font-[400] text-[#9c9c9e]">
              Can’t find your bank account?{" "}
              <span
                onClick={() => navigate("/profile?sub=banks")}
                className="cursor-pointer text-[#6935d3]"
              >
                Add a new one
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const BankCard = ({ id, name, bank, bankImage, isSelected, onClick }) => {
  return (
    <div
      className={`w-[489px] h-[79px] items-center flex justify-start gap-[10px] p-5 rounded-[14px] border-[1px] hover:border-[#6935D3] ${
        isSelected ? "border-[#6935D3]" : "border-[#f0f0f5]"
      } drop-shadow-custom cursor-pointer`}
      onClick={onClick}
    >
      <div>
        <img src={bankImage} alt="" className="w-[38px] h-[38px]" />
      </div>
      <div className="flex flex-col gap-[2px]">
        <div className="text-[16px] font-[500] text-[#131e3d]">{name}</div>
        <div className="text-[12px] font-[400] text-[#9c9c9e]">{bank}</div>
      </div>
    </div>
  );
};

export default ChooseBank;
