import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import Switcher from "./Switcher";
import over from "../Assets/Images/overview.png";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../store/actions/dashboard/manageFunds";
import CustomAreaChart from "./Chart/Line";
import { getMetrics } from "../store/actions/dashboard/getMetrics";
import { formatAmount } from "../utils/formatAmount";
import CustomTooltip from "./containers/ToolTip";

const DashboardPortfolioOverview = ({ currency, setCurrency }) => {
  const dispatch = useDispatch();

  const { pending } = useSelector((state) => state?.dashboard);
  const balances = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );

  const dollarBalance = balances?.dollarBalance;
  const investment_balance_ngr = balances?.investment_balance_ngr;
  const investment_balance_usd = balances?.investment_balance_usd;
  const walletBalance = balances?.walletBalance;

  const investmentMetrics = useSelector(
    (state) => state?.dashboard?.investmentMetrics
  );

  const ngrInvestmentBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(investment_balance_ngr || 0);

  const usdInvestmentBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(investment_balance_usd || 0);
  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);
  const totalNGNBalance = useMemo(
    () =>
      new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      }).format(
        (Number(walletBalance) || 0) + (Number(investment_balance_ngr) || 0)
      ),
    [investment_balance_ngr, walletBalance]
  );

  const totalUSDBalance = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(
        (Number(dollarBalance) || 0) + (Number(investment_balance_usd) || 0)
      ),
    [investment_balance_usd, dollarBalance]
  );

  useEffect(() => {
    dispatch(getMetrics(currency === "Naira" ? "NGR" : "USD"));
  }, [currency]);
  useEffect(() => {
    dispatch(getBalance());
  }, []);

  return (
    <div className=" rounded-[12px]  px-3 py-4  sm:px-5 sm:py-5 max-w-[698px] md:h-[309px] bg-[#f2f2f7] border-[1.1px] border-[#f2f2f2] ">
      <div className="flex justify-between p-2 flex-wrap items-start">
        <div className="flex flex-col items-start justify-start ">
          <div className=" flex gap-[2px] items-center ">
            <h4 className=" text-[14px] font-[400] text-[#9c9c9e] ">
              Total Balance
            </h4>
            <CustomTooltip
              style={{
                height: "16px",
                width: "16px",

                marginTop: "-4px",
              }}
              message={
                "The combined total of your wallet balance, investment balance, and savings balance."
              }
            >
              <span className="cursor-pointer text-[14px] font-[400] text-[#6935D3] ">
                ?
              </span>
            </CustomTooltip>
          </div>
          <h1 className=" text-[32px] font-[500] text-[#131e3d]  ">
            {pending
              ? "--"
              : currency === "Naira"
              ? totalNGNBalance?.split(".")[0]
              : totalUSDBalance?.split(".")[0]}
            <span className=" text-[16px] font-[500] text-[#9c9c9e] ">
              .
              {pending
                ? "--"
                : currency === "Naira"
                ? totalNGNBalance?.split(".")[1]
                : totalUSDBalance?.split(".")[1]}
            </span>{" "}
          </h1>
          <h3 className="  text-[#2CB171] font-[400] text-[14px] ">
            {" "}
            {/* {`+${currency === "Naira" ? "₦" : "$"}${
              investmentMetrics?.reduce((acc, curr) => {
                return acc + curr?.expected_amount;
              }, 0)
                ? formatAmount(
                    investmentMetrics?.reduce((acc, curr) => {
                      return acc + curr?.expected_amount;
                    }, 0),
                    2
                  )
                : pending
                ? "--"
                : "0.00"
            } expected in returns`} */}
          </h3>
        </div>
        <Switcher handleCurrencyChange={setCurrency} />
      </div>
      <div className=" bg-[#9c9c9e] border-[#9c9c9e]  mt-[63px] mx-auto w-full h-[1px] "></div>
      <div className="flex flex-wrap justify-start items-center gap-[18px] mt-[20px] ">
        <div className="flex p-3 flex-col gap-[8px] ">
          <h4 className=" text-[14px] font-[400] text-[#9c9c9e]">
            {" "}
            Wallet Balance{" "}
          </h4>

          <h2 className=" text-[20px] font-[500] text-[#131e3d] ">
            {currency === "Naira" ? formattedWalletBalance : usdFormatter}{" "}
          </h2>
        </div>
        <div className="flex flex-col p-3 gap-[8px] ">
          <h4 className=" text-[14px] font-[400] text-[#9c9c9e]">
            Investment Balance{" "}
          </h4>

          <h2 className=" text-[20px] font-[500] text-[#131e3d] ">
            {currency === "Naira" ? ngrInvestmentBalance : usdInvestmentBalance}{" "}
          </h2>
        </div>
        <div className="flex flex-col p-3 gap-[8px] ">
          <h4 className=" text-[14px] font-[400] text-[#9c9c9e]">
            {" "}
            Savings Balance{" "}
          </h4>

          <h2 className=" text-[20px] font-[500] text-[#131e3d] ">
            {currency === "Naira" ? "₦" : "$"} 0.00{" "}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default DashboardPortfolioOverview;
