import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useFormik } from "formik";
import { signupSchema } from "../helper/validationSchemas";
import TextInput from "./Inputs/TextInput";
import { formHandler } from "../utils/formhandler";
import { registerUsers } from "../store/actions/onboarding/registerUsers";
import AuthButton from "./Buttons/AuthButton";
import PhoneInputField from "./Inputs/PhoneInputField";

const SignupForm = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    if (!state?.country) {
      navigate(-1);
    }
  }, [state]);

  const handleSubmit = (values, { setSubmitting }) => {
    const { firstName, lastName, email, password, phone, phoneCode } = values;
    dispatch(
      registerUsers({
        data: {
          name: firstName + " " + lastName,
          email,
          password,
          phone: phone?.startsWith(phoneCode)
            ? phone
            : `${phoneCode}${Number(phone)}`,
          ...state,
        },
        navigate,
        setSubmitting,
      })
    );
  };

  const form = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneCode: "+234",
      phone: "",
    },
    onSubmit: handleSubmit,
    validationSchema: signupSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className=" flex flex-col  flex-1  p-4">
      <div className="flex  drop-shadow-[0_10px_14px_0px_rgba(0,0,0,0.25)]  ">
        <div className="w-full rounded-[14px] lg:w-[493px] py-8 px-6 bg-[#FFFFFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom shadow-custom">
          <div className="flex flex-col lg:flex-row w-full md:w-[429px]  gap-4 mb-6">
            <div className="w-full flex-1   mb-6 md:mb-0">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="first-name"
              >
                First Name
              </label>
              <TextInput
                {...formHandler("firstName", form)}
                type={"firstName"}
                mainClasses={"mb-0 mt-0 !w-full  "}
              />
            </div>
            <div className="w-full  flex-1 ">
              <label
                className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
                htmlFor="last-name"
              >
                Last Name
              </label>
              <TextInput
                {...formHandler("lastName", form)}
                type={"lastName"}
                mainClasses={"mb-0 mt-0 !w-full "}
              />
            </div>
          </div>

          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="phone-number"
            >
              Email
            </label>
            <TextInput
              {...formHandler("email", form)}
              type={"email"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px] "}
            />
          </div>
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <TextInput
              {...formHandler("password", form)}
              type={"password"}
              mainClasses={"mb-0 mt-0 w-full md:w-[429px]"}
            />
          </div>
          <div className="mb-6">
            <label
              className="block tracking-wide text-[#9C9C9E] text-[14px] font-[400] mb-2"
              htmlFor="phone-number"
            >
              Phone Number
            </label>
            <div className="flex   w-full md:w-[429px]">
              <PhoneInputField
                fullWidth
                name={"phone"}
                form={form}
                id={"phone"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="items-center flex  mt-7 ">
        <input
          id="checkbox"
          type="checkbox"
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
          style={{
            color: "#6935D3",
            width: "12px",
            height: "12px",
            borderWidth: "3px",
            borderRadius: "16px",
            marginRight: "8px",
          }}
        />
        <h4 className="font-[400] lg:w-[493px] text-[16px] text-[#9C9C9E] leading-[20.8px]">
          By hitting “Sign Up”, you agree to our{" "}
          <span>
            <a
              rel="noreferrer"
              href={"https://halvestco.com/Legal/Terms.docx.pdf"}
              // download={`HALVEST TERMS`}
              target="_blank"
              className="font-[400] cursor-pointer text-center text-[16px] text-[#6935D3] leading-[20.8px]"
            >
              terms
            </a>
          </span>{" "}
          &{" "}
          <span>
            <a
              rel="noreferrer"
              href={"https://halvestco.com/Legal/Privacy%20Policy.docx.pdf"}
              // download={`HALVEST PRIVACY POLICY`}
              target="_blank"
              className="font-[400] cursor-pointer text-center text-[16px] text-[#6935D3] leading-[16.8px]"
            >
              privacy policy
            </a>
          </span>
        </h4>
      </div>

      <AuthButton
        title={"  Start Investing"}
        onClick={form.handleSubmit}
        loading={form.isSubmitting}
        disabled={!checked || form.isSubmitting}
        // mainClasses={" hover:text-[red] hover:border-[#192851] "}
        // textStyles={"group-hover:text-[#192851]"}
      />
    </div>
  );
};

export default SignupForm;
