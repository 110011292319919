import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Utils/Loader";
import FilterButton from "../../Components/Buttons/FilterButton";
import { useLocation } from "react-router-dom";
import { getStatement } from "../../store/actions/portfolio/getStatement";
import MainButton from "../../Components/Buttons/MainButton";
import DatePicker from "../../Components/Inputs/DatePicker";
import Switcher from "../../Components/Switcher";
import { FiX } from "react-icons/fi";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { formHandler } from "../../utils/formhandler";

const GetStatement = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { state } = useLocation();

  const defaultCurrency = state;
  const [currency, setCurrency] = useState(
    defaultCurrency?.value === "NGN" || defaultCurrency === "Naira"
      ? {
          label: "Naira",
          value: "NGN",
        }
      : {
          label: "USD",
          value: "USD",
        }
  );
  const [activeTab, setActiveTab] = useState("Custom Period");

  // if (!isOpen) return null;
  const dispatch = useDispatch();
  const now = new Date();
  const last2Month = new Date().setMonth(new Date().getMonth() - 2);

  const getBEDate = (date) => {
    const _date = new Date(date);
    const beDateType = `${_date?.getFullYear()}-${
      _date?.getMonth() + 1
    }-${_date?.getDate()}`;
    return beDateType;
  };
  const handleSubmit = useCallback(
    ({ start_date, end_date }, { setSubmitting }) => {
      const data = {
        // type: activeTab,
        date_to: getBEDate(end_date),
        date_from: getBEDate(start_date),
      };
      dispatch(
        getStatement({
          data,
          currency: currency?.value === "NGN" ? "NGR" : "USD",
          cb: () => {
            setSubmitting(false);
          },
          failed: () => {
            setSubmitting(false);
          },
        })
      );
    },
    [currency, activeTab]
  );

  const form = useFormik({
    initialValues: {
      start_date: dayjs(new Date().setMonth(new Date().getMonth() - 2)),
      end_date: dayjs(new Date()),
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (activeTab === "Last month") {
      form.setValues({
        start_date: dayjs(new Date().setMonth(new Date().getMonth() - 1)),
        end_date: dayjs(new Date()),
      });
    } else if (activeTab === "Year to date") {
      form.setValues({
        start_date: dayjs(new Date(new Date().getFullYear(), 0, 1)),
        end_date: dayjs(new Date()),
      });
    } else if (activeTab === "Custom Period") {
      form.setValues({
        start_date: dayjs(new Date().setMonth(new Date().getMonth() - 2)),
        end_date: dayjs(new Date()),
      });
    }
  }, [activeTab]);

  return (
    <>
      <div className="flex relative flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} />

        <div className="w-[95%] flex-wrap flex-1 h-full border-[#F2F2F2] border-[1.1px] drop-shadow-custom rounded-[12px] gap-5 pt-2 pb-20 sm:pt-10 sm:pb-10 px-8 sm:px-10 bg-[#FFF] max-w-[1664px] flex">
          <div className="flex flex-col mt-[40px] flex-[5]">
            <h2 className=" text-center sm:text-start text-[54px] font-[500] text-[#131E3D] leading-[59.4px] tracking-[-3.1%] mb-[20px] ">
              Get your {currency?.value} investment statement in seconds
            </h2>
            <h4 className="text-[22px] text-center sm:text-start font-[400] text-[#131E3D] mt-[4px]  ">
              Your investment statement will be forwarded to your email
            </h4>
          </div>
          <div className="flex flex-col flex-[4]">
            <div className="flex justify-between mb-[6px]">
              <div></div>
              <div className=" mt-[10px] ">
                <Switcher
                  handleCurrencyChange={setCurrency}
                  label={
                    defaultCurrency?.value === "NGN" ||
                    defaultCurrency === "Naira"
                      ? "Naira"
                      : "USD"
                  }
                  options={[
                    { label: "Naira", value: "NGN" },
                    { label: "USD", value: "USD" },
                  ]}
                />
              </div>
            </div>
            <div className="bg-white w-full sm:w-[553px] flex flex-col items-center sm:items-start sm:px-6  ">
              <div className="flex justify-between mb-[12px]">
                <h4 className="text-[25px]  font-[500] text-[#131E3D] mt-[4px]  ">
                  Select a date range
                </h4>
              </div>
              <div className=" mb-[15px] flex justify-between items-start   ">
                <div className="sm:w-[380px] text-[9px] border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[52px] border-[1px] rounded-[41px]  flex justify-center items-center">
                  <FilterButton
                    type="big"
                    items={[
                      { label: "Custom Period", value: "Custom Period" },
                      { label: "Last month", value: "Last month" },
                      { label: "Year to date", value: "Year to date" },
                    ]}
                    active={activeTab}
                    setActive={setActiveTab}
                  />
                </div>
              </div>

              {activeTab === "Custom Period" || true ? (
                <div className="flex w-full flex-col gap-[8px] mt-[5px] pr-[20px]">
                  <label
                    htmlFor=""
                    className="text-[14px] font-[400] text-[#9c9c9e] "
                  >
                    Choose Start Date
                  </label>
                  <DatePicker
                    disabled={activeTab !== "Custom Period"}
                    title={"Choose Start Date"}
                    id="start_date"
                    {...formHandler("start_date", form)}
                    type={"text"}
                    form={form}
                    placeholder={"Choose Start Date"}
                    mainClasses={"mb-0 mt-0 !w-full "}
                  />

                  <label
                    htmlFor=""
                    className="text-[14px] font-[400] mt-[12px] text-[#9c9c9e] "
                  >
                    Choose End Date
                  </label>
                  <DatePicker
                    disabled={activeTab !== "Custom Period"}
                    title={"Choose End Date"}
                    id="end_date"
                    {...formHandler("end_date", form)}
                    type={"text"}
                    form={form}
                    placeholder={"Choose End Date"}
                    mainClasses={"mb-0 mt-0 !w-full "}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex justify-end mt-6">
              <MainButton
                mainClasses="!h-[52px] !w-[201px] !mt-[20px] !mb-0 text-white"
                title={"Proceed"}
                onClick={form.handleSubmit}
                loading={form.isSubmitting}
                disabled={form.isSubmitting}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStatement;
