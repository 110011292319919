import React from "react";
import arrow from "../Assets/Images/arrow.png";
const Card = ({
  type,
  imageSrc,
  text,
  subText,
  className,
  onClick,
  background,
  status,
  subTextStyle,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col cursor-pointer justify-between items-start p-5 min-w-[90%] sm:min-w-[282px] rounded-xl h-[223px]  ${background} ${className}`}
    >
      <div className="flex items-center w-full justify-between">
        <img src={imageSrc} alt="icon" className="w-[55px] h-[55px]" />
        {status ? (
          <div className="px-[14px] rounded-[40px] bg-[#192851] h-[27px] flex justify-center items-center">
            <h4 className="font-[400] text-[14px] text-[#F2F2F7]">{status}</h4>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-auto py-4 w-full">
        <div className="h-[0.5px]  w-full bg-[#F2F2F7]"></div>
        <div className=" ">
          <div className="flex items-center gap-[5px] ">
            <h3 className="mt-2 text-[18px] tracking-[-1%]  text-white font-[450] ">
              {text}
            </h3>
            {type === 2 ? (
              <img src={arrow} alt="" className="mt-2 w-[12px] h-[10px] " />
            ) : (
              <></>
            )}
          </div>
          {type === 3 ? (
            <h4 className={`font-[400] text-[14px] ${subTextStyle}`}>
              {subText}
            </h4>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
