import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AboutInvesment from "../../Components/AboutInvesment";
import CampaignCard from "../../Components/CampaignCard";
import FilterButton from "../../Components/Buttons/FilterButton";
import AmountModal from "../../Components/AmountModal";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityDetails } from "../../store/actions/opportunities/getopportunitydetails";
import Loader from "../../Components/Utils/Loader";
import PinConfirmation from "../../Components/PinConfirmation";
import { submitInvestment } from "../../store/actions/investment/submitinvestment";
import Notification from "../../Components/Notification/Notification";
import InvestmentTimeline from "../../Components/InvestmentTimelineSummary";
import InvestmentTimelineModal from "../../Components/InvestmentTimelineModal";
import InvesmentCycleTimelineModal from "../../Components/InvesmentCycleTimelineModal";
import { getBusinessProducts } from "../../store/actions/opportunities/getBusinessProducts";
import { getBalance } from "../../store/actions/dashboard/manageFunds";

const InvestmentDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showNotification, setShowNotification] = useState(false);
  const [params] = useSearchParams();
  const currency = params.get("currency");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(null);
  const [timelines, showTimelines] = useState(null);
  const [activeTab, setActiveTab] = useState("active");
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { user, profile } = useSelector((state) => state.onboarding);

  const { state } = useLocation();

  const [cycles, setCycles] = useState([]);
  const opportunity = state?.opportunity;
  const { pending: loading } = useSelector((state) => state.opportunities);

  const [loadingCycles, setLoadingCycles] = useState(false);
  useEffect(() => {
    setLoadingCycles(true);
    dispatch(
      getBusinessProducts({
        id,
        currency,
        cb: (res) => {
          setLoadingCycles(false);

          setCycles(res);
        },
        failed: () => {
          setLoadingCycles(false);
        },
      })
    );
  }, [dispatch, id]);

  const handleConfirm = ({
    otp,
    adminFee,
    investment_product_id,
    amount,
    id,
    name,
    opportunity,
    investment,
    campaign,
  }) => {
    const total = adminFee + +amount;
    const data = {
      data: {
        amount: Number(amount),
        investment_product_id: Number(campaign?.id),
        cycle_id: Number(id),
        payment_type: "transfer",
        transaction_pin: otp,
      },
      name: name,
      failed: () => {
        setShowConfirmationModal(null);
        setShowModal(showConfirmationModal?.showModalData);
      },
      cb: (response) => {
        setShowConfirmationModal(null);
        navigate(`/investment-summary/${id}`, {
          state: {
            adminFee,
            amount,
            id,
            name,
            investment,
            opportunity,
            response,
            campaign,
          },
        });
      },
    };

    dispatch(submitInvestment(data));
  };

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  useEffect(() => {
    if (!opportunity) {
      navigate(-1);
    }
  }, []);

  console.log(cycles[0]?.cycles, "cycles[0]?.cycles");

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] min-h-[90vh] h-full flex-col md:flex-row flex-wrap rounded-[12px] gap-10 p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  max-w-[1664px] flex">
          <div className="flex flex-col flex-[5]">
            <div
              onClick={() => navigate(-1)}
              className="flex cursor-pointer items-center mb-4"
            >
              <LiaAngleLeftSolid
                size={18}
                color="#131E3D"
                className="mx-[2px]  "
              />
              <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
            </div>
            {!opportunity?.name ? (
              <Loader />
            ) : (
              <>
                <h2 className="text-[44px] leading-[49.4px] lg:text-[54px] capitalize  mb-6 font-[400] lg:leading-[59.4px] tracking-[-3.1%] max-w-[650px]">
                  {opportunity?.name}
                </h2>
                <AboutInvesment opportunity={opportunity} />
              </>
            )}
          </div>
          {loading || !opportunity?.name || loadingCycles ? (
            <div className="flex-[4] h-[60vh] flex justify-center items-center ">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col flex-[4]">
              <div className="w-min border-[#F0F0F5] drop-shadow-custom mb-4 p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
                <FilterButton
                  items={[
                    { label: "Open", value: "active" },
                    { label: "Closed", value: "closed" },
                    // { label: "Exited", value: "exited" },
                  ]}
                  active={activeTab}
                  setActive={setActiveTab}
                />
              </div>
              <>
                {activeTab === "active"
                  ? cycles[0]?.cycles
                      ?.filter((investment) => investment?.status == 1)
                      ?.map((item, ind) => {
                        return (
                          <CampaignCard
                            key={ind}
                            showTimelines={() => {
                              showTimelines({
                                cycle: item,
                                investment: cycles[0],
                              });
                            }}
                            investors={item?.investors}
                            title={item?.name}
                            tenure={item?.duration}
                            nReturn={Number(item?.roi)}
                            onClick={() => {
                              dispatch(
                                getBalance({
                                  cb: () => {},
                                })
                              );
                              setShowModal({
                                cycle: item,
                                campaign: cycles[0],
                              });
                            }}
                            payoutType={
                              item?.repayment_type === "PMCE" ||
                              item?.payout_type === "PMCE"
                                ? "Profit Monthly, Capital at the end"
                                : item?.repayment_type === "PACM" ||
                                  item?.payout_type === "PACM"
                                ? "Profit and Capital Monthly"
                                : item?.repayment_type === "PACE" ||
                                  item?.payout_type === "PACE"
                                ? "Profit and Capital at the end"
                                : item?.repayment_type || item?.payout_type
                            }
                            investment_memo={item?.investment_memo}
                            dueDate={item?.due_date}
                            invested={
                              item?.investors?.reduce((acc, curr) => {
                                return acc + +curr?.amount;
                              }, 0) || 0
                            }
                            totalAmount={Number(item?.amount)}
                            currency={item?.currency === "USD" ? "$" : "₦"}
                          />
                        );
                      })
                  : activeTab === "closed"
                  ? cycles[0]?.cycles
                      ?.filter(
                        (investment) =>
                          investment?.status == 0 || investment?.status == 6
                      )
                      ?.map((item, ind) => {
                        return (
                          <CampaignCard
                            key={ind}
                            showTimelines={() => {
                              showTimelines({
                                cycle: item,
                                investment: cycles[0],
                              });
                            }}
                            title={item?.name}
                            tenure={item?.duration}
                            nReturn={Number(item?.roi)}
                            noButton={true}
                            onClick={() => {
                              dispatch(
                                getBalance({
                                  cb: () => {},
                                })
                              );
                              setShowModal({
                                cycle: item,
                                campaign: cycles[0],
                              });
                            }}
                            payoutType={
                              item?.repayment_type === "PMCE" ||
                              item?.payout_type === "PMCE"
                                ? "Profit Monthly, Capital at the end"
                                : item?.repayment_type === "PACM" ||
                                  item?.payout_type === "PACM"
                                ? "Profit and Capital Monthly"
                                : item?.repayment_type === "PACE" ||
                                  item?.payout_type === "PACE"
                                ? "Profit and Capital at the end"
                                : item?.repayment_type || item?.payout_type
                            }
                            investment_memo={item?.investment_memo}
                            dueDate={item?.due_date}
                            invested={
                              Number(item?.amount) ||
                              item?.investors?.reduce((acc, curr) => {
                                return acc + +curr?.amount;
                              }, 0) ||
                              0
                            }
                            totalAmount={Number(item?.amount)}
                            currency={item?.currency === "USD" ? "$" : "₦"}
                          />
                        );
                      })
                  : cycles[0]?.cycles?.map((item, ind) => {
                      return (
                        <CampaignCard
                          key={ind}
                          showTimelines={() => {
                            showTimelines({
                              cycle: item,
                              investment: cycles[0],
                            });
                          }}
                          title={item?.name}
                          tenure={item?.duration}
                          nReturn={Number(item?.roi)}
                          onClick={() => {
                            dispatch(
                              getBalance({
                                cb: () => {},
                              })
                            );
                            setShowModal({
                              cycle: item,
                              campaign: cycles[0],
                            });
                          }}
                          payoutType={
                            item?.repayment_type === "PMCE" ||
                            item?.payout_type === "PMCE"
                              ? "Profit Monthly, Capital at the end"
                              : item?.repayment_type === "PACM" ||
                                item?.payout_type === "PACM"
                              ? "Profit and Capital Monthly"
                              : item?.repayment_type === "PACE" ||
                                item?.payout_type === "PACE"
                              ? "Profit and Capital at the end"
                              : item?.repayment_type || item?.payout_type
                          }
                          investment_memo={item?.investment_memo}
                          dueDate={item?.due_date}
                          invested={
                            Number(item?.amount) ||
                            item?.investors?.reduce((acc, curr) => {
                              return acc + +curr?.amount;
                            }, 0) ||
                            0
                          }
                          totalAmount={Number(item?.amount)}
                          currency={item?.currency === "USD" ? "$" : "₦"}
                        />
                      );
                    })}
              </>
            </div>
          )}
        </div>
      </div>
      {timelines ? (
        <div className=" top-0 left-0 w-screen h-screen  z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <InvesmentCycleTimelineModal
            investment={timelines?.cycle}
            investmentId={timelines?.investment?.id}
            close={() => showTimelines(false)}
          />
        </div>
      ) : (
        <></>
      )}
      {showModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <AmountModal
            values={showModal?.cycle}
            campaign={showModal?.campaign}
            admin_fee={showModal?.cycle?.regular_admin_fee}
            handleClose={() => setShowModal(false)}
            next={(value) => {
              setShowConfirmationModal({ value, showModalData: showModal });
              setShowModal(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {showConfirmationModal ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          <PinConfirmation
            handleClose={() => {
              setShowConfirmationModal(null);
            }}
            back={() => {
              setShowConfirmationModal(null);
              setShowModal(showConfirmationModal?.showModalData);
            }}
            handleSubmit={(otp) => {
              handleConfirm({
                ...showConfirmationModal?.value,
                otp,
                opportunity,
                investment_product_id: opportunity?.id,
              });
            }}
            buttonTitle={"Submit Investment"}
            subTitle={"Confirm your Halvest PIN to Invest"}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InvestmentDetails;
