import React from "react";
import close from "../../Assets/Images/close.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import copy from "../../Assets/Images/copy.png";
import { toast } from "react-toastify";

const AccountDetails = ({ handleCloseModal, from, currency, handleBack }) => {
  const handleCopyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      toast.success("Account Details copied.");
    } catch (error) {
      // console.error("Failed to copy text to clipboard:", error);
    }
  };

  const accountInfoText = `
    Account Name: Halcredit Technologies Ltd
    Account Number: 8810496364
    ABA Routing Code: 026073150
    Bank Name: Community Federal Savings Bank
    Bank Address: 8916 Jamaica Ave, Woodhaven, NY 11421, United States
    Country: United States
    Beneficiary Address: 12f Tola Adewunmi Street Maryland, Lagos, Nigeria            
`;

  return (
    <div
      className={`flex flex-col items-center border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] pb-[35px]  mx-auto mb-[30px] ${
        from === "Nigeria" ? "h-[200px]" : "h-[458px]"
      } rounded-[14px] shadow-xl relative bg-white `}
    >
      <div className="absolute top-3 p-3 right-3 cursor-pointer">
        <img
          onClick={handleCloseModal}
          src={close}
          alt="Close"
          className="w-6 h-6"
        />
      </div>
      <div className="flex sticky mt-[40px] items-center justify-between w-full px-8">
        <div
          className="flex  items-center gap-[3px] cursor-pointer"
          onClick={handleBack}
        >
          <LiaAngleLeftSolid size={18} color="#9c9c9e" className="mr-2" />
          <h3 className="text-[14px] font-[400] text-[#9c9c9e]">Back</h3>
        </div>
        {from !== "Nigeria" ? (
          <div className="text-[16px] text-[#131e3d] font-[400]">
            {currency === "Naira" ? "Bank Transfer" : "USD Bank Transfer"}
          </div>
        ) : (
          <></>
        )}
        <div className="w-[40px] h-[20px]"></div>
      </div>

      {from === "Nigeria" ? (
        <div className="flex-1  flex items-center overflow-y-auto">
          <div className="w-full px-8  text-[15px] font-[400] text-[#9c9c9e] text-left">
            To get account details please send an email to{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:support@halvestco.com"
              className="text-[#6935D3]"
            >
              support@halvestco.com
            </a>{" "}
            or{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:rsanusi@halvestco.com"
              className="text-[#6935D3]"
            >
              rsanusi@halvestco.com.
            </a>
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-y-auto">
          <div className="w-full px-8 mt-[70px]">
            {[
              { label: "Account Name", value: "Halcredit Technologies Ltd" },
              { label: "IBAN", value: "8810496364" },
              { label: "SWIFT Code", value: "CMFGUS33" },
              { label: "Bank Name", value: "Community Federal Savings Bank" },
              {
                label: "Bank Address",
                value: "8916 Jamaica Ave, Woodhaven, NY 11421, United States",
              },
              { label: "Country", value: "United States" },
              {
                label: "Beneficiary Address",
                value: "12f Tola Adewunmi Street Maryland, Lagos, Nigeria",
              },
            ].map((item, index) => (
              <div key={index}>
                <div className="flex justify-between items-center mt-[20px]">
                  <div className="text-[14px] font-[400] text-[#9c9c9e]">
                    {item.label}
                  </div>
                  <div className="text-[14px] font-[400] text-[#131e3d]">
                    {item.value}
                  </div>
                </div>
                <div className="h-[1px] bg-[#f2f2f7] my-[10px]"></div>
              </div>
            ))}
          </div>
          <div
            onClick={() => {
              handleCopyToClipboard(accountInfoText);
            }}
            className="flex w-full px-8 mt-[25px] items-center cursor-pointer"
          >
            <img src={copy} alt="Copy" className="w-[14px] h-[14px]" />
            <h4 className="text-[12px] font-[400] text-[#6935D3] ml-2">
              Copy Account Details
            </h4>
          </div>
          <div className="w-full px-8 mt-[40px] text-[12px] font-[400] text-[#9c9c9e] text-left">
            Make payment using the banking details above and then send evidence
            of payment to{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:support@halvestco.com"
              className="text-[#6935D3]"
            >
               support@halvestco.com
            </a>
             and copy 
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:invest@halvestco.com"
              className="text-[#6935D3]"
            >
              {" "}
              invest@halvestco.com.{" "}
            </a>{" "}
            Your wallet will be funded as soon as your payment has been
            confirmed. This can take a few days depending on where you’re
            transferring from.
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
