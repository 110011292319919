import MainButton from "../Buttons/MainButton";
import React from "react";
import TextInput from "../Inputs/TextInput";
import { formHandler } from "../../utils/formhandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { profileDetailsSchema } from "../../helper/validationSchemas";
import PhoneInputField from "../Inputs/PhoneInputField";
import DatePicker from "../Inputs/DatePicker";
import SelectField from "../Inputs/SelectField";
import {
  // countries,
  genderOptions,
  nigerian,
  nigerianStates,
} from "../../utils/data";
import { updateUserProfile } from "../../store/actions/profile/updateProfile";
import { convertBirthDate } from "../../utils/convertDOB";
import dayjs from "dayjs";

import { Country, State, City } from "country-state-city";

const Personal = ({ user, profile }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();
    const birthDate = new Date(form.values.dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    if (age < 18) {
      // dispatch(showErrorModal("You must be 18 years or older to proceed."));
      return;
    }

    const formattedBirthDate = convertBirthDate(new Date(values.birth_date));

    formData.append(
      "country_of_residence",
      values?.country_of_residence?.value
    );

    formData.append("first_name", values?.first_name);
    formData.append("last_name", values?.last_name);

    formData.append("address", form.values?.address);
    formData.append("country", form.values.country.value);
    formData.append("state", form.values.state?.value || form.values.state);
    formData.append("city", form.values.city?.value || form.values.city);
    formData.append("postal_code", form.values.postal_code);

    formData.append("gender", values?.gender?.value?.toLowerCase());
    formData.append(
      "phone",
      values?.phone?.startsWith(values?.phoneCode)
        ? values?.phone
        : `${values?.phoneCode}${values?.phone}`
    );
    formData.append("birth_date", formattedBirthDate);
    // formData.append("email", values.email);

    dispatch(
      updateUserProfile({
        data: formData,
        navigate,
        formValues: values,
        from: "settings",
        cb: () => {
          setSubmitting(false);
        },
        setSubmitting,
      })
    );
  };

  const form = useFormik({
    initialValues: {
      first_name: profile?.first_name || user?.name?.split(" ")[0] || "",
      last_name: profile?.last_name || user?.name?.split(" ")[1] || "",
      email: user?.email,
      birth_date: profile?.birth_date ? dayjs(profile?.birth_date) : null,
      phoneCode: "+234",
      address: profile?.address || "",

      country: profile?.country
        ? {
            label: profile?.country,
            value: profile?.country,
            code: Country?.getAllCountries()?.find(
              (country) => country?.name === profile?.country
            )?.isoCode,
          }
        : user?.country
        ? {
            label: user?.country,
            value: user?.country,
            code: Country?.getAllCountries()?.find(
              (country) => country?.name === user?.country
            )?.isoCode,
          }
        : null,
      state: { label: profile?.state, value: profile?.state },
      city: profile?.city,
      // state: nigerianStates?.find(
      //   (item) => item?.value?.toLowerCase() === profile?.state?.toLowerCase()
      // ),
      // city: nigerian
      //   .find(
      //     (item) => item.state?.toLowerCase() === profile?.state?.toLowerCase()
      //   )
      //   ?.lgas?.map((item) => {
      //     return { value: item, label: item };
      //   })
      //   ?.find((item) => {
      //     return item.value?.toLowerCase() === profile?.city?.toLowerCase();
      //   }),
      postal_code: profile?.postal_code || "",
      phone: user?.phone || profile?.phone || "",
      country_of_residence: profile?.country_of_residence
        ? {
            label: profile?.country_of_residence,
            value: profile?.country_of_residence,
          }
        : user?.country_of_residence
        ? {
            label: user?.country_of_residence,
            value: user?.country_of_residence,
            code: Country?.getAllCountries()?.find(
              (country) => country?.name === user?.country_of_residence
            )?.isoCode,
          }
        : null,
      gender: profile?.gender
        ? {
            label: profile?.gender,
            value: profile?.gender,
          }
        : null,
    },
    onSubmit: handleSubmit,
    validationSchema: profileDetailsSchema,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="w-full md:w-[642px] min-h-[423px] bg-white border-[1.2px] rounded-[14px] border-[#F0F0F5] drop-shadow-custom  p-[30px]">
      <div className=" py-1  mb-4">
        <h2 className="text-[20px] font-[500] text-[#131e3d]"> Personal </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            First Name{" "}
          </label>

          <TextInput
            {...formHandler("first_name", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            Last Name{" "}
          </label>
          <TextInput
            {...formHandler("last_name", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Nationality{" "}
          </label>
          <div className="w-full ">
            <SelectField
              id="country"
              disabled={profile?.country === "Nigeria"}
              form={form}
              options={["Nigeria"]?.map((i) => ({
                label: i,
                value: i,
              }))}
              // clearFields={["state", "city", "address", "postal_code"]}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Country of Residence{" "}
          </label>
          <SelectField
            id="country_of_residence"
            form={form}
            options={Country.getAllCountries()?.map((i) => ({
              label: i?.name,
              value: i?.name,
              code: i?.isoCode,
            }))}
            clearFields={["state", "city", "address", "postal_code"]}
            sx={{ width: "100%", height: "50px" }}
          />
          {/* <TextInput
            {...formHandler("country-of-residence", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          /> */}
          {/* <div className="w-full ">
            <SelectField
              id="city"
              form={form}
              clearFields={["address", "postal_code"]}
              options={
                form?.values?.state?.value
                  ? nigerian
                      .find(
                        (item) =>
                          item.state?.toLowerCase() ===
                          form?.values?.state?.value?.toLowerCase()
                      )
                      ?.lgas?.map((item) => {
                        return { value: item, label: item };
                      })
                  : []
              }
              sx={{ width: "100%", height: "50px" }}
            />
          </div> */}
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            {form?.values?.["country_of_residence"]?.code === "CA"
              ? "Provinces"
              : "State"}{" "}
          </label>
          <SelectField
            id="state"
            form={form}
            options={State.getAllStates()
              ?.filter(
                (state) =>
                  state?.countryCode ===
                  form?.values?.["country_of_residence"]?.code
              )
              ?.map((i) => ({
                label: i?.name,
                value: i?.name,
              }))}
            clearFields={["state", "city", "address", "postal_code"]}
            sx={{ width: "100%", height: "50px" }}
          />

          {/* <div className="w-full ">
            <SelectField
              id="state"
              form={form}
              clearFields={["city", "address", "postal_code"]}
              options={
                form?.values?.country?.value?.toLowerCase() === "nigeria"
                  ? nigerianStates
                  : []
              }
              sx={{ width: "100%", height: "50px" }}
            />
          </div> */}
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            Street Address{" "}
          </label>
          <TextInput
            {...formHandler("address", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Postal code{" "}
          </label>

          <TextInput
            {...formHandler("postal_code", form)}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            Phone{" "}
          </label>
          <div className="flex   w-full">
            <PhoneInputField
              fullWidth
              name={"phone"}
              form={form}
              id={"phone"}
            />
          </div>
        </div>
        {/* <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Country of Residence{" "}
          </label>
          <div className="w-full ">
            <SelectField
              id="country_of_residence"
              form={form}
              options={countries?.map((i) => ({
                label: i,
                value: i,
              }))}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>
        </div> */}
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Date of Birth{" "}
          </label>
          <DatePicker
            title={"Date of Birth"}
            id="birth_date"
            {...formHandler("birth_date", form)}
            type="age"
            form={form}
            placeholder={"Date of Birth"}
            mainClasses={"mb-0 mt-0 !w-full "}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-[14px] font-[400] text-[#9C9C9E]">
            {" "}
            Gender{" "}
          </label>
          <div className="w-full ">
            <SelectField
              title="Gender"
              id="gender"
              form={form}
              options={genderOptions}
              sx={{ width: "100%", height: "50px" }}
            />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <MainButton
          mainClasses="!w-[169px] !my-0 text-white"
          title={"Update"}
          onClick={form.handleSubmit}
          loading={form.isSubmitting}
          disabled={form.isSubmitting}
        />
      </div>
    </div>
  );
};

export default Personal;
