import React from "react";
import withdraw from "../Assets/Images/withdraw.png";

const WithdrawButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="transition duration-[800ms] ease-in-out hover:scale-[102%] shadow-custom  gap-[5px] items-center cursor-pointer mb-4 flex w-full sm:w-[230px] md:w-[282px] h-[75px] rounded-[14px] border-[1px] bg-white p-6 "
    >
      <img src={withdraw} alt="" className=" w-[48px] h-[48px] " />
      <div className=" text-[#131E3D] font-[600] text-[20px] ">Send money</div>
    </div>
  );
};

export default WithdrawButton;
