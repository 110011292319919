import React from "react";
import deposit from "../Assets/Images/deposit.png";

const DepositButton = ({ title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="transition duration-[800ms] ease-in-out hover:scale-[102%]  shadow-custom gap-[5px] cursor-pointer items-center mb-4 flex w-[100%] sm:w-[230px] md:w-[282px] h-[75px] rounded-[14px] bg-[#6935D3] p-6 "
    >
      <img src={deposit} alt="" className=" w-[48px] h-[48px] " />
      <div className=" text-white font-[500] text-[20px] "> {title} </div>
    </div>
  );
};

export default DepositButton;
