import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import RecentTransaction from "../../Components/RecentTransaction";
import { FiMoreVertical } from "react-icons/fi";
import Card from "../../Components/Card";
import bus from "../../Assets/Images/bus.png";
import realEstate from "../../Assets/Images/real-estate.png";
import startup from "../../Assets/Images/startup.png";
import Switcher from "../../Components/Switcher";
import InvestmentTable from "../../Components/InvestmentTable";
import BusinessSlide1 from "../../Components/BusinessSlide1";
import BusinessSlide2 from "../../Components/BusinessSlide2";
import BusinessSlide3 from "../../Components/BusinessSlide3";
import BusinessSlide4 from "../../Components/BusinessSlide4";
import RealEstateSlide1 from "../../Components/RealEstateSlide1";
import RealEstateSlide2 from "../../Components/RealEstateSlide2";
import RealEstateSlide3 from "../../Components/RealEstateSlide3";
import RealEstateSlide4 from "../../Components/RealEstateSlide4";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useSelector } from "react-redux";
import Notification from "../../Components/Notification/Notification";
import VentureInvestmentSlide1 from "../../Components/VentureInvestmentSlide1";
import VentureInvestmentSlide2 from "../../Components/VentureInvestmentSlide2";
import VentureInvestmentSlide3 from "../../Components/VentureInvestmentSlide3";

const Invest = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const cookies = new Cookies();
  const [showNotification, setShowNotification] = useState(false);
  const { user } = useSelector((state) => state.onboarding);

  // const seenBF = localStorage.getItem(
  //   `viewed-business-financing-investment-popups-${user?.email}`
  // );
  const seenBF = false;
  const seenRS = cookies.get(`realEstate-${user?.email}`);
  const seenVI = cookies.get(`VentureInvesting-${user?.email}`); // For Venture Investing
  const [showTransactionTable, setShowTransactionTable] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentSlides, setCurrentSlides] = useState("");
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const _currency = params.get("currency");

  const [currency, setCurrency] = useState(
    _currency === "USD"
      ? { label: "USD Investments", value: "USD" }
      : { label: "Naira Investments", value: "NGR" }
  );
  const [currency2, setCurrency2] = useState(_currency || "Naira");

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-col bg-[#F9FAFA] gap-6 pt-3 pb-3 items-center min-h-screen">
        <Navbar type={3} handleNotification={handleToggleNotification} />

        <div className="w-[95%] h-full  rounded-[12px] gap-5 p-5 md:p-10 bg-[#FFF] border-[#F2F2F2] border-[1.1px] drop-shadow-custom  sm:max-w-[1664px] flex">
          <div className="flex flex-col flex-1">
            <div className="w-[158px] mb-4">
              <Switcher
                handleCurrencyChange={setCurrency}
                label={
                  _currency === "USD" ? "USD Investments" : "Naira Investments"
                }
                options={[
                  { label: "Naira Investments", value: "NGR" },
                  { label: "USD Investments", value: "USD" },
                ]}
              />
            </div>

            <h2 className=" font-[400] md:text-[54px] text-[44px] leading-[49.4px] md:leading-[59.4px]  tracking-[-3.1%] ">
              Explore ethical <br />
              alternative investments (
              {currency?.value === "NGR" ? "NGN" : "USD"})
            </h2>
            <div>
              <div className="max-w-[731px] flex items-center justify-between mb-5 mt-10 mr-1 ">
                <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                  Opportunities
                </h4>
              </div>
              <div className="gap-6 flex-wrap flex">
                <Card
                  className="transition duration-[800ms] ease-in-out hover:scale-[102%]    hover:bg-customPurple/90 "
                  onClick={() => {
                    if (seenBF) {
                      navigate(
                        `/investment/businessFinancing?currency=${currency?.value}`
                      );
                    } else {
                      setCurrentSlides("business");
                      setCurrentStep(1);
                      // cookies.set(`Business Financing-${user?.email}`, "true");
                    }
                  }}
                  type={3}
                  background="bg-customPurple w-full sm:w-[352px]"
                  text="Business Financing"
                  subText="Earn passive income investing in businesses"
                  imageSrc={bus}
                  subTextStyle="text-[#F1EDFB]"
                />
                <Card
                  className="transition duration-[800ms] ease-in-out hover:scale-[102%]    hover:bg-[#131E3D]/90  !cursor-not-allowed"
                  onClick={() => {
                    // if (seenRS) {
                    //   navigate(
                    //     `/investment/realEstate?currency=${currency?.value}`
                    //   );
                    // } else {
                    //   setCurrentSlides("realEstate");
                    //   setCurrentStep(1);
                    //   // cookies.set(`realEstate-${user?.email}`, "true");
                    // }
                  }}
                  type={3}
                  background="bg-[#131E3D] w-full sm:w-[352px]"
                  text="Real Estate"
                  status={"Coming Soon"}
                  subText="Invest in real estate ventures and earn.. "
                  imageSrc={realEstate}
                  subTextStyle="text-[#F2F2F7]"
                />
                <Card
                  className="transition duration-[800ms] ease-in-out hover:scale-[102%]   hover:bg-[#131E3D]/90 !cursor-not-allowed "
                  onClick={() => {
                    // if (seenVI) {
                    //   navigate(`/investment/ventureInvesting`);
                    // } else {
                    //   setCurrentSlides("venture");
                    //   setCurrentStep(1);
                    //   // cookies.set(`VentureInvesting-${user?.email}`, true);
                    // }
                  }}
                  type={3}
                  background="bg-[#131E3D] w-full sm:w-[352px]"
                  text="Venture Investing"
                  status={"Coming Soon"}
                  subText="Invest in the next big startups at seed stage"
                  imageSrc={startup}
                  subTextStyle="text-[#F2F2F7]"
                />
              </div>
            </div>

            {/* <div>
              <div className="max-w-[1011px] flex items-center justify-between mb-5 mt-14 pr-1 ">
                <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                  My Investments
                </h4>
                <div className=" flex items-center gap-[3px] ">
                  <Switcher
                    handleCurrencyChange={setCurrency2}
                    label={_currency === "usd" ? "USD" : "Naira"}
                    options={[
                      { label: "Naira", value: "NGN" },
                      { label: "USD", value: "USD" },
                    ]}
                  />
                  <FiMoreVertical
                    className="cursor-pointer"
                    size={20}
                    color={"#131E3D"}
                  />
                </div>
              </div>
              {true ? (
                <InvestmentTable
                  tableStyle="max-w-[1011px]"
                  currency={currency}
                />
              ) : (
                <RecentTransaction
                  onClick={() => {
                    setCurrentStep(1);
                  }}
                  tableStyle="!max-w-[1011px]"
                />
              )}
            </div> */}
          </div>
        </div>
      </div>
      {currentStep ? (
        <div className=" top-0 left-0 w-screen h-screen z-50 flex justify-center items-center fixed bg-[rgba(19,30,61,0.4)]">
          {currentSlides === "realEstate" ? (
            <>
              {currentStep === 1 ? (
                <RealEstateSlide1
                  handleClose={() => setCurrentStep(null)}
                  next={() => setCurrentStep(2)}
                  skip={() =>
                    navigate(
                      `/investment/realEstate?currency=${currency?.value}`
                    )
                  }
                />
              ) : currentStep === 2 ? (
                <RealEstateSlide3
                  handleClose={() => setCurrentStep(null)}
                  skip={() =>
                    navigate(
                      `/investment/realEstate?currency=${currency?.value}`
                    )
                  }
                  next={() => setCurrentStep(4)}
                />
              ) : currentStep === 4 ? (
                <RealEstateSlide4
                  handleClose={() => setCurrentStep(null)}
                  next={() =>
                    navigate(
                      `/investment/realEstate?currency=${currency?.value}`
                    )
                  }
                />
              ) : (
                <></>
              )}
            </>
          ) : currentSlides === "business" ? (
            <>
              {currentStep === 1 ? (
                <BusinessSlide1
                  currency={currency?.value}
                  skip={() =>
                    navigate(
                      `/investment/businessFinancing?currency=${currency?.value}`
                    )
                  }
                  handleClose={() => setCurrentStep(null)}
                  next={() => setCurrentStep(2)}
                />
              ) : currentStep === 2 ? (
                <BusinessSlide2
                  skip={() =>
                    navigate(
                      `/investment/businessFinancing?currency=${currency?.value}`
                    )
                  }
                  handleClose={() => setCurrentStep(null)}
                  next={() => setCurrentStep(3)}
                />
              ) : currentStep === 3 ? (
                <BusinessSlide4
                  skip={() =>
                    navigate(
                      `/investment/businessFinancing?currency=${currency?.value}`
                    )
                  }
                  handleClose={() => setCurrentStep(null)}
                  next={() =>
                    navigate(
                      `/investment/businessFinancing?currency=${currency?.value}`
                    )
                  }
                />
              ) : (
                // ) : currentStep === 4 ? (
                //   <BusinessSlide4
                //     skip={() =>
                //       navigate(
                //         `/investment/businessFinancing?currency=${currency?.value}`
                //       )
                //     }
                //     handleClose={() => setCurrentStep(null)}
                //     next={() =>
                //       navigate(
                //         `/investment/businessFinancing?currency=${currency?.value}`
                //       )
                //     }
                //   />
                <></>
              )}
            </>
          ) : currentSlides === "venture" ? (
            <>
              {currentStep === 1 ? (
                <VentureInvestmentSlide1
                  handleClose={() => setCurrentStep(null)}
                  next={() => setCurrentStep(2)}
                />
              ) : currentStep === 2 ? (
                <VentureInvestmentSlide2
                  handleClose={() => setCurrentStep(null)}
                  next={() => setCurrentStep(3)}
                />
              ) : currentStep === 3 ? (
                <VentureInvestmentSlide3
                  handleClose={() => setCurrentStep(null)}
                  next={() => navigate(`/investment/ventureInvesting`)}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Invest;
