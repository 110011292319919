import React, { useEffect } from "react";
import close from "../Assets/Images/close.png";
import { useSelector } from "react-redux";
const BusinessSlide1 = ({ handleClose, currency, next, skip }) => {
  const { user } = useSelector((state) => state.onboarding);
  // useEffect(() => {
  //   localStorage.setItem(
  //     `viewed-business-financing-investment-popups-${user?.email}`,
  //     true
  //   );
  // }, []);
  return (
    <div className=" mb-6 py-6 px-3 sm:px-6 border-[1.2px] border-[#F0F0F5] rounded-xl mx-auto w-[90%]  md:w-[553px] h-[598px] max-h-[95%]  bg-white flex flex-col shadow-2xl justify-start ">
      <div className=" flex justify-end ">
        <img
          onClick={handleClose}
          src={close}
          alt=""
          className="cursor-pointer w-[24px] h-[24px] "
        />
      </div>
      <div className=" mb-[5px] w-[48px] h-[48px] rounded-full bg-[#F1EDFB] font-[400] text-[20px] flex justify-center items-center text-[#6935D3] ">
        1
      </div>

      <div className=" flex gap-[3px] p-3 mt-[8px] mb-[10px] ">
        <div className=" w-[6px] h-[6px] bg-[#6935D3] rounded-full "></div>
        <div className=" w-[6px] h-[6px] bg-[#F2F2F7] rounded-full "></div>
        <div className=" w-[6px] h-[6px] bg-[#F2F2F7] rounded-full "></div>
      </div>
      <div className="flex-1 overflow-y-scroll">
        <div className=" text-[24px] text-[#131E3D] font-[500] ">
          Introducing Business Financing
        </div>
        <div className=" text-[16px] text-[#9C9C9E] font-[400] ">
          Unlock a unique investment opportunity that involves investing in
          profitable businesses with Murabaha financing (inventory & asset
          funding).{" "}
          <span className=" text-black ">
            Minimum investment of{" "}
            {currency === "NGR" ? "₦ 1,000,000" : "$ 1,000"}
          </span>
        </div>

        <div className=" mx-auto w-[466px] max-w-[100%]">
          <div className="flex mt-[60px]  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              Target net annual profit
            </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">
              {currency === "NGR" ? "25 - 30%" : "10 - 12%"}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-[466px] max-w-[100%]">
          <div className="flex mt-[37px] items-center  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              Risk Level{" "}
            </div>
            <div className=" text-[#A96704] h-[25px] w-[120px] flex justify-center text-[16px] bg-[#FFF2C2] p-[px] rounded-2xl font-[400] ">
              Moderate Risk{" "}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" mx-auto w-[466px] max-w-[100%]">
          <div className="flex mt-[37px]  justify-between">
            <div className=" text-[16px] font-[400] text-[#9C9C9E] ">
              Target Duration{" "}
            </div>
            <div className=" text-[#131E3D] text-[16px] font-[400] ">
              {" "}
              3-12 months{" "}
            </div>
          </div>
          <div className=" mt-[10px] w-full bg-[#F2F2F7] h-[1.5px]  "></div>
        </div>

        <div className=" gap-[7px] mt-[13px] p-2  flex justify-end items-center ">
          <button
            onClick={skip}
            className=" w-[149px] h-[45px] border border-[#F2F2F7] drop-shadow-custom rounded-[8px] bg-white  text-[#FF4848] font-[500] text-[16px] "
          >
            Skip{" "}
          </button>
          <button
            onClick={next}
            className=" w-[149px] h-[45px] rounded-[8px] bg-[#6935D3]  text-white  font-[500] text-[16px] "
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessSlide1;
