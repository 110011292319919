import React, { useEffect, useState } from "react";
import ImagePicker from "../../Components/Inputs/ImagePicker";
import { updateUserProfile } from "../../store/actions/profile/updateProfile";
import { useFormik } from "formik";
import help from "../../Assets/Images/help.png";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import bolt from "../../Assets/Images/bolt.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineWarningAmber } from "react-icons/md";
import { toast } from "react-toastify";

const Main = ({ setIDStatus, setShowBVNModal }) => {
  const navigate = useNavigate();

  const { user, profile, pending } = useSelector((state) => state.onboarding);

  const dispatch = useDispatch();
  const [submittingPic, setSubmittingPic] = useState(false);

  const handleSubmit = (values) => {
    setSubmittingPic(true);
    const formData = new FormData();
    formData.append("profile_pic", values.profile_pic);

    dispatch(
      updateUserProfile({
        data: formData,
        navigate,
        formValues: values,
        from: "settings",
        cb: () => {
          setSubmittingPic(false);
        },
      })
    );
  };
  const form = useFormik({
    initialValues: {
      profile_pic: null,
    },
    onSubmit: handleSubmit,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
  });

  //   useEffect(() => {
  //     //  handleSubmit(form?.values);
  //   }, [form?.values?.profile_pic]);

  useEffect(() => {
    if (profile?.profile_pic) {
      form.setFieldValue("profile_pic", profile?.profile_pic);
    }
  }, [profile?.profile_pic]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://dashboard.qoreid.com/qoreid-sdk/qoreid.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [showVerifyMe, setShowVerifyMe] = useState(false);

  return (
    <div className="w-full sm:w-[333px]  mx-auto lg:mx-0 flex flex-col items-center mb-8 sm:mb-0 sm:p-[20px]">
      <ImagePicker
        loading={submittingPic}
        type={"pic"}
        form={form}
        id={"profile_pic"}
      />

      <div className=" mt-[18px] flex justify-center items-center p-2 bg-[#F2F2F7] rounded-[30px] w-[77px] h-[38px] ">
        <div className=" text-[14px] font-[500] text-[#6935D3] ">Elite</div>
        <img src={bolt} alt="" className=" w-[18px] h-[18px] " />
      </div>
      <div className="text-[24px] font-[500] text-[#131e3d] mt-2">
        {user?.name}
      </div>
      <div className="flex items-center gap-[1px] mt-1">
        <div className="text-[14px] font-[400] text-[#6935D3]">
          {user?.email}
        </div>
        <img
          src={help}
          alt="Help"
          className="cursor-pointer w-[14px] h-[14px] "
        />
      </div>
      <div className="w-[100%] sm:w-[333px] mt-[30px] rounded-[14px] border-[1.2px] border-[#F0F0F5]">
        <div className="py-2 rounded-[14px]">
          <div
            className="flex items-center gap-3 px-4 py-3 border-b-[1.2px] border-b-[#F0F0F5] cursor-pointer"
            onClick={() => {
              if (profile?.id_verified) {
              } else if (profile?.gender) {
                setIDStatus("submit");
                // setShowVerifyMe(true);
              } else {
                toast.error("Complete your profile to continue.");
              }
            }}
          >
            {showVerifyMe ? (
              <></>
            ) : (
              <>
                <div
                  className={`items-center justify-center flex w-[30px] h-[30px] rounded-full ${
                    profile?.id_verified
                      ? "bg-[rgba(228,255,194,1)]"
                      : "bg-[#F1EDFB]"
                  }`}
                >
                  {profile?.id_verified ? (
                    <IoMdCheckmark color={"#1C1B1F"} size={"14px"} />
                  ) : (
                    <MdOutlineWarningAmber color={"#1C1B1F"} size={"16px"} />
                  )}
                </div>
                <div
                  className={`text-[14px] font-[500] ${
                    profile?.id_verified ? "text-[#131e3d]" : "text-[#6935D3]"
                  }`}
                >
                  ID Verification
                </div>
              </>
            )}

            {/* clientId="CIY48EIW5HJ9L5Z3RE68" */}

            {/* <div
              className={showVerifyMe ? "" : "hidden"}
              dangerouslySetInnerHTML={{
                __html: `
     <qoreid-button
            id="QoreIDButton"
            clientId="OW0HQ0WN5C9CY0JI2OOO"
            productCode="liveness_drivers_license"
            customerReference="fsdfsdf"
            applicantData='{"firstname": "${
              profile?.first_name || user?.name?.split(" ")[1]
            }", "lastname": "${
                  profile?.last_name || user?.name?.split(" ")[2]
                }", "phone":   "${
                  user?.phone || profile?.phone || ""
                }", "email": "${user?.email || ""}"}'
          ></qoreid-button>
          `,
              }}
            /> */}
          </div>
          {/* <div className="flex items-center gap-3 px-4 py-3 border-b-[1.2px] border-b-[#F0F0F5]"> */}
          <div
            onClick={() => {
              if (profile?.BVN || user?.bvn_verified) {
              } else if (profile?.gender) {
                setShowBVNModal(true);
              } else {
                toast.error("Complete your profile to continue.");
              }
            }}
            className="flex items-center gap-3 px-4 py-3 cursor-pointer"
          >
            <div
              className={`items-center justify-center flex w-[30px] h-[30px] rounded-full ${
                profile?.BVN || user?.bvn_verified
                  ? "bg-[rgba(228,255,194,1)]"
                  : "bg-[#F1EDFB]"
              }`}
            >
              {profile?.BVN || user?.bvn_verified ? (
                <IoMdCheckmark color={"#1C1B1F"} size={"14px"} />
              ) : (
                <MdOutlineWarningAmber color={"#1C1B1F"} size={"16px"} />
              )}
            </div>
            <span
              className={`text-[14px] font-[500] ${
                profile?.BVN || user?.bvn_verified
                  ? "text-[#131e3d]"
                  : "text-[#6935D3]"
              }`}
            >
              BVN Verification
            </span>
          </div>
          {/* <div className="flex items-center gap-3 px-4 py-3 ">
            <div
              className={`items-center justify-center flex w-[30px] h-[30px] rounded-full ${
                user?.email_verified
                  ? "bg-[rgba(228,255,194,1)]"
                  : "bg-[#F1EDFB]"
              }`}
            >
              {user?.email_verified ? (
                <IoMdCheckmark color={"#1C1B1F"} size={"14px"} />
              ) : (
                <MdOutlineWarningAmber color={"#1C1B1F"} size={"16px"} />
              )}
            </div>
            <span
              className={`text-[14px] font-[500] ${
                user?.email_verified ? "text-[#131e3d]" : "text-[#6935D3]"
              }`}
            >
              Account Verification
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
