import React from "react";
import cs from "../Assets/Images/bell.png";
import locations from "../Assets/Images/location_on.png";

const InvestmentCard = ({
  imageSrc,
  campaign,
  section1,
  section2,
  location,
  title,
  onClick,
  open,
  closed,
  text,
}) => {
  return (
    <div
      onClick={onClick}
      className=" flex flex-col rounded-[14px] overflow-hidden justify-between items-start transition-all ease-in-out duration-100 border-[1.1px] cursor-pointer border-[#f0f0f5]  pb-5 max-w-[82vw] sm:max-w-[370px] w-full sm:w-[339px] h-[303px] "
    >
      <div className="w-full h-[171px] bg-[#FFF1DB] relative overflow-hidden">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="icon"
            className=" w-full h-full  object-cover object-center"
          />
        ) : (
          <></>
        )}
        <div className="absolute flex-wrap justify-end top-0 right-0 flex gap-2 mt-5 px-5">
          <div className="gap-[5px] flex rounded-[40px]  h-[28px] min-w-[107px] px-4 text-[12px] bg-[#E4FFC2] text-[#344616] justify-center items-center font-[500]">
            <img
              src={cs}
              alt=""
              className="w-[12.16px] h-[12.62px] rotate-[-7.86deg]"
            />
            <span className="ml-[1px]"> Open</span>
            <span className=" font-[500] text-[10px] ml-[5px] h-[17.59px] w-[17.59px] flex justify-center items-center bg-[#FFF] text-[#000000] rounded-[6.74px] ">
              {open}
            </span>
          </div>
          <div className="gap-[5px] flex rounded-[40px]  h-[28px] min-w-[107px] px-4 text-[12px] bg-[#F2F7F5] text-[#344616] justify-center items-center font-[500]">
            <span className="ml-[5px]"> Closed</span>
            <span className=" font-[500] text-[10px] ml-[5px] h-[17.59px] w-[17.59px] flex justify-center items-center bg-[#FFF] text-[#000000] rounded-[6.74px] ">
              {closed}
            </span>
          </div>
          {/* <div className="gap-[5px] flex rounded-[40px]  h-[28px] min-w-[107px] px-4 text-[12px] bg-[#F2F7F5] text-[#20847C] justify-center items-center font-[500]">
            <img src={cs} alt="" className="w-[16px] h-[16px]" />
            <span className="ml-[5px]">Coming Soon</span>
          </div> */}
        </div>
      </div>
      <div className="mt-1 px-4 w-full">
        <div className="h-[0.5px]  w-full bg-[#F2F2F7]"></div>
        <div className=" mt-2 text-[16px] text-left text-[#131E3D] font-[400]">
          {title}
        </div>
        <div className="flex mt-[5px] items-center text-[#9C9C9E] text-[14px] font-[400]">
          <img src={locations} alt="" className="w-[16px] h-[16px]" />
          <span className=" ml-[5px]">{location}</span>
        </div>
        <div className="flex gap-[6px] mt-[10px]">
          {section1 ? (
            <div className="min-w-[81px]  h-[22px] rounded-[40px] px-3 bg-[#f2f2f7] flex justify-center items-center text-[12px] font-[400] text-[#131E3D]">
              {section1}
            </div>
          ) : (
            <></>
          )}
          {section2 ? (
            <div className="min-w-[81px] truncate h-[22px] rounded-[40px] px-3 bg-[#f2f2f7] flex justify-center items-center text-[12px] font-[400] text-[#131E3D]">
              {section2}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
