import React, { useState } from "react";
import close from "../Assets/Images/close.png";
import { LiaAngleLeftSolid } from "react-icons/lia";
import Loader from "./Utils/Loader";
import OTPInput from "react-otp-input";

const PinConfirmation = ({
  handleClose,
  back,
  buttonTitle,
  subTitle,
  handleSubmit,
}) => {
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");

  const handleVerify = (_otp) => {
    setLoading(true);
    handleSubmit(_otp);
    // setOtp("");
    // handleClose();
  };

  return (
    <div className="relative px-[20px] pb-6 mb-[30px] mx-auto w-full sm:w-[553px] min-h-[388px] rounded-[14px] border-[1px] bg-[#FFF] border-[#f0f0f5] drop-shadow-custom">
      <div
        onClick={handleClose}
        className="absolute top-3 right-3 cursor-pointer px-3 py-2"
      >
        <img src={close} alt="Close" className="w-6 h-6" />
      </div>
      <div className="mt-[40px]  flex flex-col gap-4">
        <div
          className="flex justify-between items-center flex-wrap cursor-pointer"
          onClick={back}
        >
          <div className=" flex items-center gap-[3px]">
            <LiaAngleLeftSolid size={18} color="#131E3D" className="mr-2" />
            <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
          </div>
          <div className="text-[16px] text-center justify-cente font-[500] text-[#131e3d]">
            Confirm Transaction PIN
          </div>
          <div className="w-[60px] h-[10px]"></div>
        </div>
      </div>
      <div className="hidden sm:flex justify-center mt-[80px] gap-[7px] items-center ">
        <OTPInput
          value={otp}
          onChange={setOtp}
          inputType="number"
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          placeholder="----"
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "34px",
            // marginBottom: "18px",
          }}
          inputStyle={{
            width: "50px",
            "-webkit-text-security": "disc",
            height: "64px",
            width: "64px",
            fontWeight: "500",
            border: "1.1px solid #F2F2F2",
            borderRadius: "10px",
            fontWeight: "bold",
            color: "#131E3D",
            fontSize: "32px",
            marginLeft: "6px",
            marginRight: "6px",
          }}
        />
      </div>
      <div className="flex sm:hidden  justify-center mt-[80px] gap-[7px] items-center ">
        <OTPInput
          value={otp}
          onChange={setOtp}
          inputType="number"
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          placeholder="----"
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "34px",
            // marginBottom: "18px",
          }}
          inputStyle={{
            width: "50px",
            "-webkit-text-security": "disc",
            height: "44px",
            width: "44px",
            fontWeight: "500",
            border: "1.1px solid #F2F2F2",
            borderRadius: "10px",
            fontWeight: "bold",
            color: "#131E3D",
            fontSize: "32px",
            marginLeft: "6px",
            marginRight: "6px",
          }}
        />
      </div>

      <div
        onClick={
          otp?.length === 4
            ? () => {
                handleVerify(otp);
              }
            : null
        }
        className={` justify-center mx-auto items-center flex text-[16px] text-white font-[500] rounded-[8px] w-full sm:w-[266px] h-[45px] bg-[#6935D3] mt-[90px] ${
          otp?.length !== 4 ? "opacity-75 cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {loading ? <Loader /> : buttonTitle ? buttonTitle : "Add Bank Account"}
      </div>

      <div className=" text-center text-[14px] mt-[10px] font-[400] text-[#9c9c9e] ">
        {subTitle
          ? subTitle
          : "Confirm your Halvest PIN to add this bank account"}
      </div>
    </div>
  );
};

export default PinConfirmation;
