import React, { useEffect, useState } from "react";
import DepositButton from "../DepositButton";
import WithdrawButton from "../WithdrawButton";
import { fetchUserProfile } from "../../store/actions/profile/getProfile";
import { useDispatch, useSelector } from "react-redux";
import BVNModal from "../BVNModal";
import { allDashboard } from "../../store/actions/dashboard/allDashboard";
import Switcher from "../Switcher";

const BalanceCard = ({
  handleShowDepositSlide,
  handleShowWithdrawSlide,
  setShowBVNModal,
  currency,
  setCurrency,
}) => {
  const dispatch = useDispatch();
  const walletBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.walletBalance
  );
  const dollarBalance = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data?.dollarBalance
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(walletBalance || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarBalance || 0);

  const { profile } = useSelector((state) => state.onboarding);

  return (
    <>
      <div className="drop-shadow-custom p-7 md:w-[696px] md:h-[285px] rounded-[12px] border-[1px] border-[#f2f2f2]">
        <div className="flex  justify-between">
          <div className="text-[14px] font-[400] text-[#9c9c9e] mb-[7px]">
            Total Balance
          </div>
          <Switcher handleCurrencyChange={setCurrency} />
        </div>
        <div className="text-[32px] font-[500] text-[#131e3d]">
          {currency === "Naira"
            ? formattedWalletBalance?.split(".")[0]
            : usdFormatter?.split(".")[0]}
          <span className=" text-[#9C9C9E] text-[16px] font-[500] ">
            .
            {currency === "Naira"
              ? formattedWalletBalance?.split(".")[1]
              : usdFormatter?.split(".")[1]}
          </span>
        </div>
        <div className="mt-[30px] gap-[10px] flex-wrap items-center flex">
          {profile && !profile?.BVN && false ? (
            <DepositButton
              onClick={() => setShowBVNModal(true)}
              title={"Verify BVN"}
            />
          ) : (
            <>
              <DepositButton
                onClick={handleShowDepositSlide}
                title={"Add money"}
              />

              <WithdrawButton onClick={handleShowWithdrawSlide} />
            </>
          )}
        </div>
        <div className="mt-[20px] text-[14px] font-[400] text-[#9c9c9e]">
          Your wallet holds funds for your investments
        </div>
      </div>
    </>
  );
};

export default BalanceCard;
