import React, { useEffect, useState } from "react";
import PortfolioCard from "./PortfolioCard";
import right from "../Assets/Images/right.png";
import left from "../Assets/Images/left.png";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolio } from "../store/actions/portfolio/getportfolio";
import SelfDirected from "../Assets/Icons/selfdirect.png";
import managed from "../Assets/Icons/managed.png";

const FollowAndTrack = () => {
  const [currency, setCurrency] = useState({
    label: "Naira Porfolio",
    value: "NGN",
  });
  const dispatch = useDispatch();
  const { portfolio, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );

  const totalRealEstate = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name === "Real Estate" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;

  const totalBFinancing = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name === "SME Financing" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;

  const Total_Investment = portfolio?.reduce(
    (sum, item) => sum + +item?.amount,
    0
  );
  const Total_BF_Investment = portfolio
    ?.filter((i) => i?.investment_category?.name === "SME Financing")
    ?.reduce((sum, item) => sum + +item?.amount, 0);
  const Total_RE_Investment = portfolio
    ?.filter((i) => i?.investment_category?.name === "Real Estate")
    ?.reduce((sum, item) => sum + +item?.amount, 0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPortfolio(currency?.value || currency));
  }, [currency]);

  useEffect(() => {
    if (portfolioPending === false) {
      setLoading(false);
    }
  }, [portfolioPending]);

  return (
    <div className=" w-full max-w-[698px] mt-9 sm:mt-[70px] ">
      <div className=" mb-3 items-center px-[2px] flex justify-between ">
        <div className=" flex gap-[8px] items-center ">
          <div className=" text-[16px] font-[500] text-[#131E3D]  ">
            Follow our wealth tracks
          </div>
          <div className=" flex items-center gap-[6px] ">
            <img
              src={left}
              alt=""
              className="cursor-pointer w-[14px] h-[14px] "
            />
            <img
              src={right}
              alt=""
              className="cursor-pointer w-[14px] h-[14px] "
            />
          </div>
        </div>
      </div>
      <div className="flex-col md:flex-row flex gap-[18px] flex-wrap sm:items-center ">
        <PortfolioCard
          img={SelfDirected}
          // onClick={() => {}}
          main={"Self directed"}
          type={2}
          subtitle={
            "Take control of your investments in our high-value alternative investment campaigns. Select a campaign tailored to your goals and watch your wealth grow"
          }
        />
        <PortfolioCard
          img={managed}
          tag="Coming Soon"
          main={"Managed"}
          type={3}
          subtitle={
            "Let our experts build you a custom portfolio designed to maximize returns, tailored to your financial goals, while you enjoy peace of mind."
          }
        />
      </div>
      <div className="flex gap-2 mt-3 mb-5 px-[2px]">
        <div className="w-[6px] h-[6px] bg-[#192851] rounded-full"></div>
        <div className="w-[6px] h-[6px] bg-[#192851] rounded-full opacity-30"></div>
      </div>
    </div>
  );
};

export default FollowAndTrack;
