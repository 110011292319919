import React, { useState } from "react";
import logo from "../Assets/Images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import notify from "../Assets/Images/notify.png";
import { LiaAngleDownSolid } from "react-icons/lia";
import { logout } from "../store/actions/user/logout";
import { useDispatch, useSelector } from "react-redux";
import supportIcon from "../Assets/Images/chat_bubble.png";
import Support from "./support/Support";
import Notification from "./Notification/Notification";

const Navbar = ({ type }) => {
  const { user, profile } = useSelector((state) => state.onboarding);

  const activeLink = useLocation()?.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLinkClick = (link) => {
    navigate(link);
  };

  const [showNotification, setShowNotification] = useState(false);
  const [support, setshowSupport] = useState(false);

  const handleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  const handleSupport = () => {
    setshowSupport((prev) => !prev);
  };

  return (
    <>
      {type === 3 ? (
        <div className="pt-3 w-full bg-[#F9FAFA] sticky top-0 flex justify-center z-[40] ">
          <div className=" flex justify-between  bg-[#FFFFFF] px-[32px] w-[95%] border-[#F2F2F2] border-[1.1px] drop-shadow-custom rounded-[12px] max-w-[1664px] h-[71px]  items-center">
            <div>
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={logo}
                alt="logo"
                className="w-[100px] cursor-pointer h-[24px]"
              />
            </div>

            <div className="hidden md:flex gap-[7px]">
              {[
                { name: "Home", link: "/" },
                { name: "Invest", link: "/investment" },
                { name: "Save", link: "/saving" },
                { name: "Wallet", link: "/wallet" },
              ].map((link) => (
                <Link
                  key={link?.name}
                  className={`flex hover:bg-[#F2F2F7] transition-all ease-in-out duration-400 hover:text-[#131E3D] justify-center items-center text-[16px] font-[500] tracking-[-1%] w-[61px] h-[39px] rounded-[10px] px-[13px] ${
                    activeLink === link?.link ||
                    (link?.link?.length > 2 &&
                      activeLink?.includes(link?.link)) ||
                    (link?.link === "/" && activeLink === "/dashboard")
                      ? "bg-[#F2F2F7] text-[#131E3D]"
                      : "text-[#9C9C9E]"
                  } `}
                  onClick={() => handleLinkClick(link?.link)}
                >
                  {link?.name}
                </Link>
              ))}
            </div>

            <div className="flex md:hidden gap-[7px]">
              {[
                // { name: "Home", link: "/" },
                { name: "Invest", link: "/investment" },
                // { name: "Save", link: "/saving" },
                { name: "Wallet", link: "/wallet" },
              ].map((link) => (
                <Link
                  key={link?.name}
                  className={`flex hover:bg-[#F2F2F7] transition-all ease-in-out duration-400 hover:text-[#131E3D] justify-center items-center text-[14px] font-[500] tracking-[-1%] w-[57px] h-[35px] rounded-[10px] px-[13px] ${
                    activeLink === link?.link ||
                    (link?.link?.length > 2 &&
                      activeLink?.includes(link?.link)) ||
                    (link?.link === "/" && activeLink === "/dashboard")
                      ? "bg-[#F2F2F7] text-[#131E3D]"
                      : "text-[#9C9C9E]"
                  } `}
                  onClick={() => handleLinkClick(link?.link)}
                >
                  {link?.name}
                </Link>
              ))}
            </div>

            <div className="flex items-center gap-[6px]">
              {/* <img
                onClick={handleSupport}
                src={supportIcon}
                alt=""
                className=" w-[24px] cursor-pointer h-[24px] mr-3 "
              /> */}
              <img
                onClick={handleNotification}
                src={notify}
                alt="notify"
                className="mr-5 hidden md:block cursor-pointer w-[24px]  h-[24px]"
              />
              <div
                onClick={() => navigate("/profile")}
                className="hidden md:block cursor-pointer text-[#131E3D] capitalize font-[500] mr-1 text-[16px]"
              >
                {profile?.first_name || user?.name?.split(" ")[0]}{" "}
                {profile?.last_name?.slice(0, 1) ||
                  user?.name?.split(" ")[1]?.slice(0, 1)}
              </div>
              <div
                onClick={() => navigate("/profile")}
                className="cursor-pointer flex items-center justify-center bg-blue-950 text-white w-[32px] h-[32px] rounded-full"
              >
                {profile?.profile_pic ? (
                  <img
                    src={profile?.profile_pic}
                    alt="camera"
                    className="w-full object-cover  h-full  rounded-full"
                  />
                ) : (
                  <h4 className="font-[500]">
                    {profile?.first_name?.slice(0, 1) ||
                      user?.name?.split(" ")[0]?.slice(0, 1)}
                  </h4>
                )}
              </div>

              <div
                className={` group relative    transition-all duration-500 ease-in-out cursor-pointer`}
              >
                <LiaAngleDownSolid
                  size={20}
                  color="#131E3D"
                  className="mb-1 cursor-pointer"
                />

                <div className="absolute overflow-hidden rounded-[10px] transition-all ease-in-out duration-[100ms]   flex-col gap-1 justify-center items-center top-8 right-0 w-[120px]  group-hover:bg-[#FFF] group-hover:border h-[5px] group-hover:h-auto group-hover:opacity-[100] opacity-0 ">
                  <div className="w-full hover:bg-[#F2F2F7] py-[10px] flex justify-center items-center">
                    <p
                      onClick={() => {
                        navigate("/profile");
                      }}
                      className={` transition-all ease-in-out duration-[300ms] hover:text-[#131E3D] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                    >
                      Profile
                    </p>
                  </div>
                  <div className="w-full hover:bg-[#FEF3F2] py-[10px] flex justify-center items-center">
                    <p
                      onClick={() => {
                        dispatch(logout());
                      }}
                      className={` transition-all ease-in-out duration-[300ms] text-[#B42318] justify-center items-center text-[14px] font-[500] tracking-wide w-[61px]     text-center      `}
                    >
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : type === 2 ? (
        <div className="w-full border-b-[1px] h-[74px] bg-white sticky top-0 py-[10px] z-50 px-16 ">
          <div className="flex justify-center p-2 h-full items-center">
            <img
              onClick={() => {
                navigate("/");
              }}
              src={logo}
              alt="Logo"
              className="w-[100px] cursor-pointer h-[24px]"
            />
          </div>
        </div>
      ) : (
        <div className="w-full border-b-[1px] h-[74px] bg-white sticky top-0 py-[10px] z-50 px-16 ">
          <div className="flex  justify-center md:justify-between p-2 h-full items-center">
            <img
              onClick={() => {
                navigate("/");
              }}
              src={logo}
              alt="Logo"
              className="w-[100px] cursor-pointer h-[24px]"
            />
            <div className="md:flex hidden  gap-10 pr-10">
              <Link to="/membership" className="text-[16px] text-[#9C9C9E]">
                Membership
              </Link>
              <Link to="/more" className="text-[16px] text-[#9C9C9E]">
                More
              </Link>
            </div>
          </div>
        </div>
      )}
      {support ? (
        <Support
          close={() => {
            setshowSupport(false);
          }}
        />
      ) : (
        <></>
      )}
      {showNotification ? (
        <Notification
          close={() => {
            setShowNotification(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Navbar;
