import React, { useEffect, useRef, useState } from "react";
import Switcher from "./Switcher";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../store/actions/dashboard/manageFunds";
import { getMetrics } from "../store/actions/dashboard/getMetrics";
import { formatAmount } from "../utils/formatAmount";
import { dashboardDropdownOptions } from "../utils/data";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import NewPortfolioComponent from "./Wallet/NewPortfolioComponent";
import { getPortfolio } from "../store/actions/portfolio/getportfolio";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "./containers/ToolTip";

const PortfolioAllocation = ({}) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const myRefFirst = useRef(null);
  const [currency, setCurrency] = useState("Naira");

  const [currentPerfomanceRange, setCurrentPerfomanceRange] = useState(
    dashboardDropdownOptions[0].value
  );

  const balances = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );

  const portfolio_value_usd = balances?.portfolio_value_usd;
  const portfolio_value_ngr = balances?.portfolio_value_ngr;

  const investmentMetrics = useSelector(
    (state) => state?.dashboard?.investmentMetrics
  );

  const formattedWalletBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(portfolio_value_ngr || 0);

  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(portfolio_value_usd || 0);

  useEffect(() => {
    dispatch(getMetrics(currency === "Naira" ? "NGR" : "USD"));
  }, [currency]);
  useEffect(() => {
    dispatch(getBalance());
  }, []);
  const { user, profile, pending } = useSelector((state) => state.onboarding);

  const navigate = useNavigate();

  const { portfolio, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );

  const totalExchangeTradedCommodities = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name === "Exchange Traded Commodities" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;
  const totalStartupInvesting = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name === "Startup Investing" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;
  const totalRealEstate = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name?.toLowerCase() === "real estate" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;

  const totalBFinancing = portfolio?.filter((item) => {
    return (
      item?.investment_category?.name?.toLowerCase() === "sme financing" &&
      !item?.paymentSchedules?.expected_date?.every(
        (paymentSchedule) => paymentSchedule?.status === "Paid"
      )
    );
  })?.length;

  const Total_Investment = portfolio?.reduce(
    (sum, item) => sum + +item?.amount,
    0
  );
  const Total_ETC_Investment = portfolio
    ?.filter(
      (i) =>
        i?.investment_category?.name?.toLowerCase() ===
        "exchange traded commodities"
    )
    ?.reduce(
      (sum, item) => (item?.status === 6 ? sum : sum + +item?.amount),
      0
    );
  const Total_SU_Investment = portfolio
    ?.filter(
      (i) => i?.investment_category?.name?.toLowerCase() === "startup investing"
    )
    ?.reduce(
      (sum, item) => (item?.status === 6 ? sum : sum + +item?.amount),
      0
    );
  const Total_BF_Investment = portfolio
    ?.filter(
      (i) => i?.investment_category?.name?.toLowerCase() === "sme financing"
    )
    ?.reduce(
      (sum, item) => (item?.status === 6 ? sum : sum + +item?.amount),
      0
    );
  const Total_RE_Investment = portfolio
    ?.filter(
      (i) => i?.investment_category?.name?.toLowerCase() === "real estate"
    )
    ?.reduce(
      (sum, item) => (item?.status === 6 ? sum : sum + +item?.amount),
      0
    );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getPortfolio(currency === "Naira" ? "NGR" : "USD"));
  }, [currency]);

  useEffect(() => {
    if (portfolioPending === false) {
      setLoading(false);
    }
  }, [portfolioPending]);
  const [currentStage, setCurrentStage] = useState(1);

  // useEffect(() => {
  //   if (currentStage === 1) {
  //     window.scrollTo({ left: 0, behavior: "smooth" });
  //   } else {
  //     window.scrollTo({ right: 0, behavior: "smooth" });
  //   }
  // }, [currentStage]);

  const dollarBalance = balances?.dollarBalance;
  const investment_balance_ngr = balances?.investment_balance_ngr;
  const investment_balance_usd = balances?.investment_balance_usd;
  const walletBalance = balances?.walletBalance;

  const ngrInvestmentBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(investment_balance_ngr || 0);

  const usdInvestmentBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(investment_balance_usd || 0);

  return (
    <div className=" p-3 flex flex-col justify-between w-full lg:max-w-[47vw] lg:w-[698px] min-h-[444px] rounded-[12px] border-[1px] bg-[#6935D3] border-[#F2F2F2] ">
      <div className=" px-2 gap-y-2 py-3 flex-wrap sm:px-6 sm:py-6 flex justify-between items-start ">
        <div>
          <div className="flex gap-[2px] items-center ">
            <h4 className=" text-[14px] font-[400] text-[#FFFFFF] ">
              Investment Balance
            </h4>
            <CustomTooltip
              style={{
                height: "16px",
                width: "16px",

                marginTop: "-4px",
              }}
              message={
                "Represents the capital allocated specifically to ongoing investments."
              }
            >
              <span className="cursor-pointer text-[14px] font-[400] text-[#FFF] ">
                ?
              </span>
            </CustomTooltip>
          </div>

          <div className=" text-[32px] font-[500] text-[#FFF] ">
            {currency === "Naira"
              ? ngrInvestmentBalance?.split(".")[0]
              : usdInvestmentBalance?.split(".")[0]}
            <span className=" text-[#FFF] text-[16px] font-[500] ">
              .
              {currency === "Naira"
                ? ngrInvestmentBalance?.split(".")[1]
                : usdInvestmentBalance?.split(".")[1]}
            </span>
          </div>
          <div className=" text-[14px] font-[400] text-[#2CB171] ">
            {`+${currency === "Naira" ? "₦" : "$"}${
              investmentMetrics
                ?.filter(
                  (item) =>
                    (new Date() - new Date(item?.date)) / 1000 / 60 / 60 / 24 <=
                    currentPerfomanceRange
                )
                ?.reduce((acc, curr) => {
                  return acc + curr?.expected_amount;
                }, 0)
                ? formatAmount(
                    investmentMetrics
                      ?.filter(
                        (item) =>
                          (new Date() - new Date(item?.date)) /
                            1000 /
                            60 /
                            60 /
                            24 <=
                          currentPerfomanceRange
                      )
                      ?.reduce((acc, curr) => {
                        return acc + curr?.expected_amount;
                      }, 0),
                    2
                  )
                : "0.00"
            } expected in returns`}
          </div>
        </div>
        <div className="">
          <Switcher
            disable={loading || portfolioPending}
            handleCurrencyChange={setCurrency}
          />
        </div>
      </div>

      <div className="  mt-[20px] p-6 pt-4 pr-0 ">
        <div className=" flex gap-[8px] items-center ">
          <div className="mb-1 text-[16px] font-[500] text-[#FFF]  ">
            Composition
          </div>
          <div className=" flex items-center gap-[6px] ">
            <FiChevronLeft
              onClick={
                currentStage > 1
                  ? () => {
                      myRefFirst.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                      setCurrentStage((prev) => --prev);
                    }
                  : null
              }
              size={"14px"}
              color={currentStage > 1 ? "#FFF" : "#9C9C9E"}
              className={`${
                currentStage > 1 ? "cursor-pointer" : "cursor-not-allowed"
              } mt-[1px] `}
            />
            <FiChevronRight
              onClick={
                currentStage < 2
                  ? () => {
                      myRef.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                      setCurrentStage((prev) => ++prev);
                    }
                  : null
              }
              size={"14px"}
              color={currentStage < 2 ? "#FFF " : "#9C9C9E "}
              className={`${
                currentStage < 2 ? "cursor-pointer" : "cursor-not-allowed"
              } mt-[1px] `}
            />
          </div>
        </div>
        <div className="transition duration-[800ms] ease-in-out flex gap-3 overflow-x-scroll w-full items-center">
          <div ref={myRefFirst} id="first-item">
            <NewPortfolioComponent
              name={"Business Financing"}
              owner={user?.name}
              onClick={() => {
                navigate("portfolio", {
                  state: {
                    current: "SME Financing",
                    currency,
                  },
                });
              }}
              loading={loading || portfolioPending}
              amount={
                loading || portfolioPending
                  ? "-"
                  : `${currency === "Naira" ? "₦" : "$"} ${formatAmount(
                      Total_BF_Investment
                    )}`
              }
              percent={
                loading || portfolioPending
                  ? "-"
                  : Number(
                      (Total_BF_Investment / Total_Investment) * 100
                        ? (
                            (Total_BF_Investment / Total_Investment) *
                            100
                          ).toFixed(2)
                        : 0
                    )
              }
            />
          </div>

          <NewPortfolioComponent
            onClick={() => {
              navigate("portfolio", {
                state: {
                  current: "Real Estate",
                  currency,
                },
              });
            }}
            owner={user?.name}
            classes={"!bg-[#E3ECFF]"}
            loading={loading || portfolioPending}
            amount={
              loading || portfolioPending
                ? "-"
                : `${currency === "Naira" ? "₦" : "$"} ${formatAmount(
                    Total_RE_Investment
                  )}`
            }
            name={`Real Estate`}
            percent={`${
              loading || portfolioPending
                ? "-"
                : Number(
                    (Total_RE_Investment / Total_Investment) * 100
                      ? (
                          (Total_RE_Investment / Total_Investment) *
                          100
                        ).toFixed(2)
                      : 0
                  )
            }`}
          />

          <NewPortfolioComponent
            onClick={() => {
              navigate("portfolio", {
                state: {
                  current: "Exchange Traded Commodities",
                  currency,
                },
              });
            }}
            classes={"#DEFFFC"}
            loading={loading || portfolioPending}
            owner={user?.name}
            amount={
              loading || portfolioPending
                ? "-"
                : `${currency === "Naira" ? "₦" : "$"} ${formatAmount(
                    Total_ETC_Investment
                  )}`
            }
            name={`Exchange Traded Commodities (${totalExchangeTradedCommodities})`}
            percent={`${
              loading || portfolioPending
                ? "-"
                : Number(
                    (Total_ETC_Investment / Total_Investment) * 100
                      ? (
                          (Total_ETC_Investment / Total_Investment) *
                          100
                        ).toFixed(2)
                      : 0
                  )
            }`}
          />
          <div ref={myRef} id="last-item">
            <NewPortfolioComponent
              onClick={() => {
                navigate("portfolio", {
                  state: {
                    current: "Startup Investing",
                    currency,
                  },
                });
              }}
              classes={"!bg-[#E3ECFF]"}
              loading={loading || portfolioPending}
              owner={user?.name}
              amount={
                loading || portfolioPending
                  ? "-"
                  : `${currency === "Naira" ? "₦" : "$"} ${formatAmount(
                      Total_SU_Investment
                    )}`
              }
              name={
                loading || portfolioPending
                  ? "-"
                  : `Startup Investing (${totalStartupInvesting})`
              }
              percent={`${
                loading || portfolioPending
                  ? "-"
                  : Number(
                      (Total_SU_Investment / Total_Investment) * 100
                        ? (
                            (Total_SU_Investment / Total_Investment) *
                            100
                          ).toFixed(2)
                        : 0
                    )
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioAllocation;
