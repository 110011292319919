import React from "react";
import { LiaAngleRightSolid } from "react-icons/lia";

const PortfoliosCard = ({
  category,
  amount,
  owner,
  portfolioPercentage,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col p-[18px] cursor-pointer w-full sm:w-[360px] h-auto border-[1.2px] bg-white border-[#F0F0F5] rounded-[14px] shadow-custom"
    >
      <div
        className={`flex gap-[5px] px-[20px] w-fit h-[35px] ${
          category === "Real Estate"
            ? "bg-[#E4FFC2]"
            : category === "Business Financing"
            ? "bg-[#A9FFF8]"
            : "bg-[#F2F2F7]"
        } justify-center rounded-[40px] items-center  py-2 mb-4`}
      >
        {category === "Real Estate" ? (
          <img
            className="w-4 h-4"
            src={require("../../Assets/Images/roofing.png")}
          />
        ) : (
          <img
            className="w-4 h-4"
            src={require("../../Assets/Images/storefront.png")}
          />
        )}
        <div className="text-[12px] font-[400] text-[#131E3D]">{category}</div>
      </div>

      <div className="text-[24px] mt-[50px] font-[500] text-[#131E3D] mb-2">
        {amount}
        <span className="text-[#9C9C9E] text-[12px] ">.00</span>
      </div>

      <div className="w-full h-[1px] bg-[#f2f2f7] my-4"></div>

      <div className="flex items-center justify-between px-1 py-3 rounded-md">
        <div className="flex flex-col">
          <div className="text-[16px] font-[400] text-[#131E3D]">{owner}</div>
          <div className="text-[14px] font-[400] text-[#9C9C9E]">
            {portfolioPercentage} of portfolio
          </div>
        </div>

        <div
          // onClick={onClick}
          className="w-[50px]  h-[50px] flex justify-center items-center bg-[#f2f2f7] rounded-full p-2"
        >
          <LiaAngleRightSolid size={30} className="text-[#131E3D]" />
        </div>
      </div>
    </div>
  );
};

export default PortfoliosCard;
