import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { LiaAngleLeftSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import TransactionTable from "../../Components/TransactionTable";
import RecentTransaction from "../../Components/RecentTransaction";
import { FiMoreVertical } from "react-icons/fi";
import PortfoliosCard from "../Auth/PortfoliosCard";
import { FaBuilding } from "react-icons/fa";
import PortfolioDateModal from "../../Components/PortfolioDateModal";
import Switcher from "../../Components/Switcher";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolio } from "../../store/actions/portfolio/getportfolio";
import Loader from "../../Components/Utils/Loader";
import { formatAmount } from "../../utils/formatAmount";
import FilterButton from "../../Components/Buttons/FilterButton";
// Import the modal

const PortfolioType = ({ back, type, _currency }) => {
  const { user } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(
    _currency === "Naira"
      ? { label: "Naira Porfolio", value: "NGR" }
      : { label: "USD Porfolio", value: "USD" }
  );
  const [showTransactionTable, setShowTransactionTable] = useState(false);

  const balances = useSelector(
    (state) => state?.dashboard?.halvestBalance?.data
  );

  const investment_balance_ngr = balances?.investment_balance_ngr;
  const investment_balance_usd = balances?.investment_balance_usd;

  const ngrInvestmentBalance = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(investment_balance_ngr || 0);

  const usdInvestmentBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(investment_balance_usd || 0);

  const { portfolio, pending: portfolioPending } = useSelector(
    (state) => state.portfolio
  );

  const Total_Investment = portfolio?.reduce(
    (sum, item) => sum + +item?.amount,
    0
  );

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Active Investments");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  useEffect(() => {
    dispatch(getPortfolio(currency?.value || currency));
  }, [currency]);

  return (
    <div className="flex flex-col bg-[#F9FAFA] items-center min-h-screen">
      {/* Navbar */}
      <Navbar type={3} />

      {/* Main Content */}
      <div className="w-[95%] max-w-[1664px] min-h-[80vh] border-[#F2F2F2] border-[1.1px] shadow-md rounded-[12px] bg-[#FFF] p-6 md:p-10 flex flex-col gap-5 mt-5">
        {/* Back Button */}
        <div onClick={back} className="flex cursor-pointer items-center gap-2">
          <LiaAngleLeftSolid size={18} color="#131E3D" />
          <h3 className="text-[14px] font-[400] text-[#131E3D]">Back</h3>
        </div>

        {/* Title and Button */}
        <div className="flex flex-col md:flex-row items-center gap-4 mt-3">
          <div className="text-[36px] md:text-[54px] font-[400] text-[#131e3d]">
            Portfolio
          </div>
        </div>

        {/* Tab Section */}
        {portfolioPending ? (
          <div className="h-[50vh] flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="sm:w-[330px] border-[#F0F0F5] drop-shadow-custom  p-2 h-[49px] border-[1px] rounded-[41px] flex justify-center items-center">
              <FilterButton
                items={[
                  { label: "Active Investments", value: "Active Investments" },
                  {
                    label: "Paid Out Investments",
                    value: "Paid Out Investments",
                  },
                ]}
                active={activeTab}
                setActive={setActiveTab}
              />
            </div>

            <div className="flex gap-[30px]">
              <div className="mt-0 flex-1">
                {/* <div className=" flex flex-col justify-between p-5 mb-[20px] max-w-[744px] h-[199px] rounded-[12px] border-[1px] border-[#f2f2f7] ">
                  <div className="flex justify-between ">
                    <h4 className=" text-[14px] font-[400] text-[#9c9c9e] ">
                      Investment Balance
                    </h4>
                    <Switcher
                      handleCurrencyChange={setCurrency}
                      label={
                        currency?.value === "NGR"
                          ? "Naira Porfolio"
                          : "USD Porfolio"
                      }
                      options={[
                        { label: "Naira Porfolio", value: "NGR" },
                        { label: "USD Porfolio", value: "USD" },
                      ]}
                    />
                  </div>
                  <div>
                    <h3 className="text-[32px] font-[500] text-[#131E3D] ">
                      {currency?.value === "NGR"
                        ? ngrInvestmentBalance
                        : usdInvestmentBalance}{" "}
                    </h3>
                    <h5 className=" text-[#2CB171] font-[400] text-[14px] ">
                      +{"0"}% gained in the last 30 days
                    </h5>
                  </div>
                </div> */}

                <div className="flex gap- items-start">
                  {activeTab === "Active Investments" ? (
                    <div className="flex w-full justify-start items-center mt-[10px] gap-[20px] flex-wrap">
                      {portfolio
                        ?.filter(
                          (item) =>
                            type?.toLowerCase() ===
                              item?.investment_category?.name?.toLowerCase() &&
                            item?.status === 1
                        )
                        ?.map((item, index) => (
                          <PortfoliosCard
                            key={index}
                            category={item?.investment_category?.name}
                            amount={`${
                              currency?.value === "NGR" ? "₦" : "$"
                            }${formatAmount(item?.amount)}`}
                            owner={item?.cycle_details?.name}
                            portfolioPercentage={`${(
                              (item?.amount / Total_Investment) *
                              100
                            ).toFixed(2)}%`}
                            onClick={() => {
                              navigate(`/investment-summary/${item?.id}`, {
                                state: {
                                  ...item,
                                  // adminFee,
                                  // amount,
                                  // id,
                                  // name,
                                  // investment,
                                  // opportunity,
                                  // response,
                                  // campaign,
                                },
                              });
                            }}
                          />
                        ))}
                    </div>
                  ) : (
                    <div className="flex w-full justify-start items-center mt-[10px] gap-[20px] flex-wrap">
                      {portfolio
                        ?.filter(
                          (item) =>
                            type?.toLowerCase() ===
                              item?.investment_category?.name?.toLowerCase() &&
                            item?.status === 6
                        )
                        ?.map((item, index) => (
                          <PortfoliosCard
                            key={index}
                            category={item?.investment_category?.name}
                            amount={`${
                              currency?.value === "NGR" ? "₦" : "$"
                            }${formatAmount(item?.amount)}`}
                            owner={item?.cycle_details?.name}
                            portfolioPercentage={`${(
                              (item?.amount / Total_Investment) *
                              100
                            ).toFixed(2)}%`}
                            onClick={() => {
                              navigate(`/investment-summary/${item?.id}`, {
                                state: {
                                  ...item,
                                  // adminFee,
                                  // amount,
                                  // id,
                                  // name,
                                  // investment,
                                  // opportunity,
                                  // response,
                                  // campaign,
                                },
                              });
                            }}
                          />
                        ))}
                    </div>
                  )}
                  {/* Recent Transactions */}
                  {/* {showTransactionTable ? (
            <TransactionTable />
          ) : (
            <RecentTransaction onClick={() => setShowTransactionTable(true)} />
          )} */}
                </div>
              </div>
              {/* <div className="w-[600px]">
                <div className=" ml-[5px] flex items-center justify-between mb-5 pr-1">
                  <h4 className="font-[500] text-[#131E3D] text-base leading-[17.6px] tracking-[-1%]">
                    Recent Transactions
                  </h4>
                  <FiMoreVertical
                    className="cursor-pointer"
                    size={20}
                    color={"#131E3D"}
                  />
                </div>

                <TransactionTable
                  currency={
                    currency?.value === "NGR" || currency === "NGR"
                      ? "Naira"
                      : "USD"
                  }
                />
              </div> */}
            </div>
          </>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <PortfolioDateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)} // Close modal when needed
        />
      )}
    </div>
  );
};

export default PortfolioType;
