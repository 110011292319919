import React from "react";
import close from "../../Assets/Images/close.png";
import { useNavigate } from "react-router-dom";
import up from "../../Assets/Images/chevron right.png";
import usdWallet from "../../Assets/Icons/usd-wallet.png";
import ngrWallet from "../../Assets/Icons/ngr-wallet.png";

import bankTransfer from "../../Assets/Icons/bank-transfer.png";

const WithdrawalOption = ({ next, currency, handleClose, move }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-start items-start border-[1px] border-[#F0F0F5] w-[90%] sm:w-[553px] mt-[30px] mx-auto mb-[30px] h-[431px] rounded-[14px]  drop-shadow-custom relative bg-white">
      <div className="absolute p-3 top-3 right-3 cursor-pointer">
        <img
          src={close}
          alt="Close"
          className=" cursor-pointer w-6 h-6 mb-[10px]"
          onClick={handleClose}
        />
      </div>
      <div className="flex mt-[60px] flex-col justify-start items-center w-full h-full">
        <h2 className="text-[16px] text-[#9c9c9e] font-[400] mb-[30px]">
          {/* Withdraw To */}
          Send To
        </h2>
        <div className="w-full flex flex-col gap-[20px] ">
          <div
            onClick={next}
            className="  cursor-pointer w-[90%] mx-auto  sm:w-[489px]  h-[70px] items-center flex justify-between p-5 rounded-[14px] border-[1px] border-[#f0f0f5] drop-shadow-custom "
          >
            <div className="flex gap-4">
              <img src={bankTransfer} alt="" className="w-[30px] h-[30px]" />

              <div className=" text-[16px] font-[500] text-[#131E3D] ">
                Bank Account
              </div>
            </div>
            <img src={up} alt="" className=" w-[20px] h-[20px] " />
          </div>

          <div
            // onClick={move}
            // className="  cursor-pointer w-[489px]  h-[70px] items-center flex justify-between p-5 rounded-[14px] border-[1px] border-[#f0f0f5] drop-shadow-custom "
            className="  cursor-not-allowed opacity-50 w-[90%] mx-auto  sm:w-[489px]  h-[70px] items-center flex justify-between p-5 rounded-[14px] border-[1px] border-[#f0f0f5] drop-shadow-custom "
          >
            <div className="flex gap-4">
              {currency === "Naira" ? (
                <img src={usdWallet} alt="" className="w-[30px] h-[30px]" />
              ) : (
                <img src={ngrWallet} alt="" className="w-[30px] h-[30px]" />
              )}
              <div className=" text-[16px] font-[500] text-[#131E3D] ">
                {currency === "Naira" ? "USD" : "Naira"} Wallet
              </div>
            </div>
            <img src={up} alt="" className=" w-[20px] h-[20px] " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalOption;
