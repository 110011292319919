import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SEO from "../../config/Seo";
import Navbar from "../../Components/Navbar";
import individual2 from "../../Assets/Images/landing-icon1.png";
import individual from "../../Assets/Images/landing-icon2.png";
import Card from "../../Components/Card";
import { LiaAngleDownSolid, LiaAngleUpSolid } from "react-icons/lia";
import why1 from "../../Assets/Icons/why-1.png";
import why2 from "../../Assets/Icons/why-2.png";
import why3 from "../../Assets/Icons/why-3.png";
import why4 from "../../Assets/Icons/why-4.png";
import FeaturesCard from "../../Components/FeaturesCard";

import dot from "../../Assets/Icons/dot.png";

const Landing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(true);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const message = queryParams.get("message");

    if (message) {
      // toast.error(message);
    }
    return () => {
      if (message) {
        navigate("/");
      }
    };
  }, []);

  return (
    <>
      <SEO
        title={"Login | Access Your Ethical Finance Investments | Halvest"}
        description={
          "Log in to your Halvest account and explore ethical finance and Islamic banking solutions."
        }
        name={"Halvest"}
        type={"article"}
      />
      <div className="flex flex-col  min-h-screen">
        <Navbar />
        <div className="flex flex-col flex-1 justify-between px-4 md:px-16">
          <div
            className="w-full flex-wrap
           flex justify-center gap-5 flex-col lg:flex-row"
          >
            <div className=" py-6  flex flex-col  flex-1">
              <h2 className="font-[500] text-center lg:text-left max-w-[80%] mx-auto lg:mx-0 lg:leading-[92.4px]  lg:max-w-[900px] tracking-[-3.1%] text-[#131E3D] text-[50px] leading-[50px] lg:text-[84px]">
                Grow your wealth, grow{" "}
                <span className="text-[#6935D3] relative inline-block">
                  good
                  <img
                    src={dot}
                    alt="Leaf"
                    className="absolute top-[6px] right-[-5px] w-[27px] h-[17px]"
                  />
                </span>
              </h2>
            </div>
            <div className=" flex flex-col  flex-1  p-4">
              <div className=" mt-[20px] ">
                <h3 className="font-[400] text-center lg:text-left max-w-[80%] mx-auto lg:mx-0 mb-8 text-[24px] text-[#131E3D]">
                  Halvest is your ethical investment platform to grow your money
                  while making a positive impact.
                </h3>
                <div className="gap-8  items-center flex-col lg:flex-row flex">
                  <Card
                    className="transition md:min-w-[377px] lg:min-w-[282px]  duration-[800ms] ease-in-out hover:scale-[102%] hover:bg-customPurple/90 "
                    type={2}
                    onClick={() => {
                      navigate("/nationality");
                    }}
                    background="bg-customPurple"
                    text="For Individuals"
                    imageSrc={individual}
                  />
                  <Card
                    className="transition duration-[800ms] md:min-w-[377px] lg:min-w-[282px] ease-in-out hover:scale-[102%] hover:bg-[#192851]/90  "
                    type={2}
                    // onClick={() => {
                    // navigate("/signup");
                    // }}
                    background="bg-[#192851]"
                    text="For Businesses"
                    imageSrc={individual2}
                  />
                </div>
                <h4 className="font-[500] text-center sm:text-left  mt-7 text-[16px] lg:w-[600px] text-[#9C9C9E] leading-[16.8px]">
                  Existing customer?{" "}
                  <span
                    onClick={() => {
                      navigate("/signin");
                    }}
                    className="font-[500] cursor-pointer  text-[16px] text-[#6935D3] leading-[16.8px]"
                  >
                    {" "}
                    Sign In instead{" "}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={() => {
                setShowDetails((prev) => !prev);
              }}
              className={`py-3 mt-10 sm:mt-0  cursor-pointer ${
                showDetails ? "border-b border-b-[#F2F2F7]" : ""
              }  flex justify-center lg:justify-start items-center gap-1`}
            >
              <h4 className="font-[500]   text-[24px]  text-[#6935D3]  tracking-[-1%]">
                Why Halvest?
              </h4>
              {showDetails ? (
                <LiaAngleDownSolid
                  size={28}
                  color="#6935D3"
                  className="mx-[2px] mb-1 "
                />
              ) : (
                <LiaAngleUpSolid
                  size={28}
                  color="#6935D3"
                  className="mx-[2px] mb-1 "
                />
              )}
            </div>
            {showDetails ? (
              <div
                className={` w-full flex-col items-center lg:items-start lg:flex-row py-6 flex transition-all ease-in-out duration-400 justify-between gap-3 ${
                  showDetails ? "flex scale-1" : "scale-0"
                }`}
              >
                <FeaturesCard
                  title="Ethical Compliance"
                  text="Rigorous Sharia compliance screening by our team of experts and in-house sharia scholars"
                  imageSrc={why1}
                />
                <FeaturesCard
                  title="Competitive Returns"
                  text="Credible ethical investment opportunities that are projected to give competitive returns."
                  imageSrc={why2}
                />
                <FeaturesCard
                  title="Safe & Trusted"
                  text="A trusted platform to learn and build wealth without feeling guilty."
                  imageSrc={why3}
                />
                <FeaturesCard
                  title="Adequate Monitoring"
                  text="Close and adequate monitoring of all investments to ensure full payment as at when due."
                  imageSrc={why4}
                />
              </div>
            ) : (
              <></>
            )}

            <h4 className="font-[400]  self-end justify-self-end mx-auto w-full my-8 text-sm text-[#9C9C9E] tracking-[-0.4%]">
              The information on this website is provided for informational
              purposes only and does not constitute investment advice. You
              should not make any investment decisions based solely on the
              information on this website. You should consult with a qualified
              financial advisor before making any investment decisions. This
              website may contain links to third-party websites. These links are
              provided for your convenience only and do not constitute an
              endorsement by Halvest of the content on such websites. Halvest is
              not responsible for the content of any linked website. This
              website and these terms of use shall be governed by and construed
              in accordance with the laws of [Your Jurisdiction], without regard
              to its conflict of laws provisions.
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
