import React, { useRef, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { timeformatter } from "../../utils/dateformatter";

const DatePicker = ({
  onChange,
  title,
  disabled,
  form,
  id,
  type,
  value,
  mainClasses,
  inputClasses,
  placeholder,
}) => {
  const currentDate = new Date();

  const dateRef = useRef();
  return (
    <div className={` w-[367px] ${mainClasses}`}>
      <div
        onClick={
          disabled
            ? null
            : () => {
                dateRef.current.showPicker();
              }
        }
        className={`flex  relative z-[1] items-center w-full  transition-all ease-in-out duration-100 rounded-[8px] px-2 md:px-4 border-[1px] border-gray-200 h-[52px]  py-3 leading-tight focus:outline-none focus:bg-white  drop-shadow-custom ${inputClasses} ${
          disabled ? "bg-[rgb(245,245,245)]" : ""
        }`}
      >
        <h4 className="font-[400] text-[14px] md:text-[16px] text-[#131E3D]">
          {value ? timeformatter(value, "formal") : placeholder}
        </h4>
        {disabled ? (
          <></>
        ) : (
          <input
            id={"id"}
            type={"date"}
            ref={dateRef}
            max={
              type === "age"
                ? new Date(
                    currentDate.setFullYear(currentDate.getFullYear() - 18)
                  )
                    .toISOString()
                    ?.split("T")[0]
                : null
            }
            // max={"2024-08-09"}
            // disabled={disabled ? disabled : false}
            className={`absolute  opacity-0`}
            // defaultValue={defaultValue}
            onChange={(e) => {
              onChange(e?.target?.value);
            }}
          />
        )}
      </div>
      {form?.errors[id] && (
        <h6 className=" text-[0.75rem] mt-[4px] px-[2px] mb-2 text-[#ef5364] text-left w-full">
          {`${form?.errors[id]}`}
        </h6>
      )}
    </div>
  );
};

export default DatePicker;
